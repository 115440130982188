import React from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import { useParams } from 'react-router-dom';
import { AuthService } from '../../service/auth';
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Typography from '@mui/material/Typography';
import { faTimes, faEllipsisH } from '@fortawesome/free-solid-svg-icons'
import Modal from '@mui/material/Modal';
import { Dropdown } from 'react-bootstrap'
import './testAccount.css';
import TablePagination from '@mui/material/TablePagination';
import { Multiselect } from "multiselect-react-dropdown";


const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

export interface TestAccountProps {
    params: any
};

export type TestAccountState = {
    accountsList: []
    page: number
    respData: any
    rowsPerPage: number
    totalCount: number
    modelTemp: boolean
    modelDel: boolean
    testPhoneNumber: string
    testType: any
    tempArray: any
    deltTmp: any
};

export class TestAccount extends React.Component<TestAccountProps, TestAccountState> {
    authService: any = new AuthService()
    testAccountTypeArray = [{ "key": "REAL", value: "Real" }, { "key": "FAKE", value: "Fake" }]
    constructor(props: any) {
        super(props);
        this.state = {
            tempArray: [],
            accountsList: [],
            deltTmp: {},
            page: 0,
            rowsPerPage: 10,
            respData: "",
            totalCount: 10,
            modelTemp: false,
            modelDel: false,
            testPhoneNumber: '',
            testType: this.testAccountTypeArray[0],
        };
    }

    createData(phoneNumber: any, type: any) {
        return {
            phoneNumber,
            type: this.testAccountTypeArray.filter(it => it.key === type)[0].value
        };
    }
    closeModal(user?: any) {
        this.setState({
            modelTemp: !this.state.modelTemp,
        })
    }

    deleteModal(phone?: any) {
        this.setState({
            deltTmp: phone,
            modelDel: !this.state.modelDel,
        })
    }

    deleteNumber = () => {
        this.authService.deletePhoneNumber(this.state.deltTmp)
            .then((resp: any) => {
                if (resp.data.message === "DELETE_ACCOUNT_SUCCESS") {
                    this.setState({
                        deltTmp: "",
                        modelDel: !this.state.modelDel,
                    })
                    this.getTestAccount()
                }
            });
    }

    addData = () => {
        let tempArray = {
            "phoneNumber": this.state.testPhoneNumber,
            "type": this.state.testType["key"]
        };
        this.authService.addAccount(tempArray)
            .then((resp: any) => {
                if (resp.data.message === "ADD_TEST_ACCOUNT_SUCCESS") {
                    this.setState({
                        modelTemp: !this.state.modelTemp,
                        testPhoneNumber: "",
                        testType: this.testAccountTypeArray[0]
                    })
                    this.getTestAccount()
                }
            });
    }

    handleChangeRowsPerPage = (event: any) => {
        this.setState({ rowsPerPage: event.target.value })
    }

    handleChangePage = (event: any, newPage: number) => {
        this.setState({ page: newPage }, () => {
            this.getTestAccount();
        })
    }

    getTestAccount() {
        this.authService.getALLTestAccount(this.state.page, this.state.rowsPerPage)
            .then((resp: any) => {
                const aList: any = []
                if (resp.data.data) {
                    resp.data.data.data.forEach((item: any) => {
                        const a = this.createData(item.phoneNumber, item.type)
                        aList.push(a)
                    });
                }
                this.setState({ accountsList: aList, totalCount: resp.data.data.totalRecords })
                let tempPlainArray: any[] = [];
                aList.forEach((itemAL: any) => {
                    tempPlainArray.push(itemAL.phoneNumber);
                });
            });
    }

    componentDidMount() {
        this.getTestAccount()
    }

    testHeader=["Phone Number", "Type"]

    render() {
        return (
            <>
                <div className="col-12">
                    <span className="col-6">
                        <Breadcrumbs>
                            <Link style={{ fontSize: "20px" }} href="">
                                Test Account
                            </Link>
                        </Breadcrumbs>
                    </span>
                    <span className="col-6">
                        <button className="addbtn text-center mb-3" onClick={() => { this.closeModal() }} ><b>Add</b></button>
                    </span>
                </div>
                <TableContainer component={Paper}>
                    <Table sx={{}} aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                {this.testHeader.map((row: any) => (
                                    <StyledTableCell>{row}</StyledTableCell>
                                ))}
                                
                                <StyledTableCell align="right">More</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.state.accountsList.map((row: any) => (
                                <StyledTableRow key={row.phoneNumber}>
                                    <StyledTableCell component="th" scope="row">
                                        {row.phoneNumber}
                                    </StyledTableCell>
                                    <StyledTableCell component="th" scope="row">
                                        {row.type}
                                    </StyledTableCell>
                                    <StyledTableCell align="right">
                                        <Dropdown>
                                            <Dropdown.Toggle id="dropdown-basic" variant="none">
                                                <FontAwesomeIcon icon={faEllipsisH} />
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                <Dropdown.Item tabindex={row.phoneNumber} onClick={() => { this.deleteModal(row.phoneNumber) }}><b>Delete</b></Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </StyledTableCell>
                                </StyledTableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={this.state.totalCount}
                    rowsPerPage={this.state.rowsPerPage}
                    page={this.state.page}
                    onPageChange={this.handleChangePage}
                    onRowsPerPageChange={this.handleChangeRowsPerPage}
                />
                <Modal
                    open={this.state.modelTemp}>
                    <div style={{
                        position: "absolute",
                        width: 550,
                        height: 450,
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        background: 'white',
                        borderRadius: '9px',
                        outline: "none"
                    }} >
                        <div className="col-12">
                            <label className="col-12 pt-3 pe-4 text-end"><FontAwesomeIcon className="col-1" onClick={() => { this.closeModal() }} icon={faTimes} /></label>
                            <Typography variant="h6" id="modal-title" className="pt-4 pb-4 row d-flex justify-content-center text-center sticky-top">
                                <b>Add Test Account</b>
                            </Typography>
                            <div className="col-12 row p-0 m-0  justify-content-center">
                                <span className="col-12 row p-0 m-0  justify-content-center">
                                    <label className="text">Phone Number</label>
                                    <input className="select1 " value={this.state.testPhoneNumber} type="number" placeholder='Enter your number' pattern="[0-9]{10}" onChange={(event) => {
                                        this.setState({ testPhoneNumber: event.target.value })
                                    }}></input>
                                    <label className="text mt-4" >Phone Number Type</label>
                                    <div className="multiselect col-12 p-0 m-0 row justify-content-center">
                                        <Multiselect showArrow singleSelect={true} options={this.testAccountTypeArray} isObject={true}
                                            displayValue="value" selectedValues={[this.state.testType]} onSelect={(event: any, list: any) => {
                                                this.setState({ testType: list })
                                            }} />
                                    </div>
                                </span>
                            </div>
                            <div className=" mt-4 row justify-content-center">
                                <button onClick={() => { this.addData() }} className="button2 col-12 col-md-4 mt-3 ms-3"><b>Save</b></button>
                            </div>
                        </div>
                    </div>
                </Modal>
                <Modal
                    open={this.state.modelDel} >
                    <div style={{
                        position: "absolute",
                        width: 550,
                        height: 350,
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        background: 'white',
                        borderRadius: '9px',
                        outline: "none"
                    }} >
                        <div className="col-12">
                            <label className="col-12 pt-3 pe-4 text-end"><FontAwesomeIcon className="col-1" onClick={() => { this.deleteModal() }} icon={faTimes} /></label>
                            <Typography variant="h6" id="modal-title" className="pt-4 pb-4 row d-flex justify-content-center text-center sticky-top">
                                <b>Confirm Delete Phone Number</b>
                            </Typography>
                            <Typography className="text-center" variant="subtitle1" id="simple-modal-description">
                                <p className="pb-5">Are you sure you want to delete </p>
                            </Typography>
                            <div className=" mt-4 row justify-content-center">
                                <button onClick={() => { this.deleteModal() }} className="button1 col-12  col-md-4 me-3"><b>Cancel</b></button>
                                <button onClick={() => { this.deleteNumber() }} className="button2 col-12 col-md-4 ms-3"><b>Yes</b></button>
                            </div>
                        </div>
                    </div>
                </Modal>
            </>
        )
    }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function (props: TestAccountProps) {
    const params = useParams();
    return <TestAccount {...props} params={params} />;
}
