import React, { Component } from 'react'
import './dashboard.css';
import { Dropdown } from 'react-bootstrap'
import Gig from '../../assets/gig.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faUserAlt } from '@fortawesome/free-solid-svg-icons'
// import { faBell, faMapMarkerAlt} from '@fortawesome/free-solid-svg-icons'
import { useNavigate, Routes, Route, Navigate } from "react-router-dom";

import Account from '../account/account'
import LoginActivity from '../loginActivity/loginActivity';
import Companies from '../companies/companies';
import Course from '../course/course';
import Interest from '../interest/interest';
import Category from '../category/category';
import Question from '../question/question';
import Skills from '../skills/skills';
import BlackList from '../blackList/blackList';
import TestAccount from '../testAccount/testAccount';
import LearnPlatForm from '../learnPlatForm/learnPlatForm';
import Career from '../career/career';
import PotentialUsers from '../potentialUsers/potentialUsers';
import MainDashboard from '../mainDashboard/mainDashboard';

import Goals from '../goals/goals';

import Events from '../events/events';
// import Button from '@material-ui/core/Button';
// import { faEllipsisH } from '@fortawesome/free-solid-svg-icons'

// import Modal from '@mui/material/Modal';
// import TablePagination from '@mui/material/TablePagination';


export type DashboardProps = {
    navigate: any
}

export type DashboardState = {
    showPopup: boolean
    childView: string
    selected: string
    // visible: boolean

};

class Dashboard extends Component<DashboardProps, DashboardState>{
    state = {
        showPopup: false,
        childView: 'mainDashboard',
        selected: 'MainDashboard',
        // visible: false,

    };

    sideBarList = [
        { childView: 'mainDashboard', name: 'Dashboard ' },
        { childView: 'account', name: 'Account ' },
        { childView: 'company', name: 'Company' },
        { childView: 'course', name: 'Course' },
        { childView: 'interest', name: 'Interest' },
        { childView: 'category', name: 'Category' },
        { childView: 'question', name: 'Question' },
        { childView: 'skills', name: 'Skills' },
        { childView: 'blackList', name: 'Blacklist' },
        { childView: 'testAccount', name: 'Test Account' },
        { childView: 'learnPlatForm', name: 'Learn Platform' },
        { childView: 'career', name: 'Career' },
        { childView: 'potentialUsers', name: 'Member Intake' }
    ]

    render() {
        // const [visible, setVisible] = React.useState(false)
        return (
            <div className="row col-12 vh-100 flexDiv">

                {/* side/bar */}
                <div className=" colum" style={{ width: "15rem", height: "100%" }}>
                    <div style={{ textAlign: "center" }}>
                        <img className="mt-4" src={Gig} alt='' />
                    </div>
                    <div className=" pt-5 ">
                        <ul className="sidebarList">
                            {this.sideBarList.map(data => {
                                return <ul key={data.name}>
                                    <label className="pb-4 ps-1" style={{ color: this.state.selected === data.name ? "black" : "gray", }}
                                        onClick={() => { this.changeChildView(data.childView, data.name) }} >
                                        {data.name}
                                    </label>
                                </ul>
                            })}
                        </ul>
                    </div>
                </div>

                <div className="col-10" style={{ height: "100%", display: "flex", flexDirection: "column"}}>
                    <div className="col-12" style={{ width: "100%", flex: "0 1 auto" }}>
                        <label className="text-center ps-0 pt-3 fa-lg" style={{ float: "right", color: "GrayText" }} onClick={() => { this.setShowPopup() }} >
                            <Dropdown>
                                <Dropdown.Toggle id="dropdown-basic" variant="none">
                                    <FontAwesomeIcon icon={faUserAlt} />
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item onClick={() => { this.logOut() }}>Logout</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </label>
                        {/* <label className="text-center ps-0 pt-4 pe-4 fa-lg" style={{ float: "right" }} >
                            <FontAwesomeIcon icon={faBell} />
                        </label>
                        <label className="d-flex justify-content-center align-items-center ps-0 fa-lg" style={{ float: "right" }}>
                            <span className="pt-4 pe-4 fa-lg" style={{ float: "right", fontSize: "15px" }} ><u>Bangalore</u></span></label>
                        <label className="text-center ps-0 pt-4 pe-2 fa-lg" style={{ float: "right" }} >
                            <FontAwesomeIcon icon={faMapMarkerAlt} />
                        </label> */}
                        <div className="" style={{ borderBottom: "1px inset", height: "90px" }}></div>
                    </div>
                    <div style={{flex: "1 1 auto", overflowY: "auto"}}>

                        <Routes>
                            <Route path="/" element={
                                <Navigate replace to="mainDashboard" />
                            } />
                            <Route path="mainDashboard" element={<MainDashboard navigate={this.props.navigate} />} />
                            <Route path="account/:id/loginActivity" element={<LoginActivity params />} />
                            <Route path="account" element={<Account  navigate={this.props.navigate} />}  />
                            <Route path="account/:id/goals" element={<Goals params navigate={this.props.navigate} />} />
                            <Route path="account/:accountId/goals/:cycleId/events" element={<Events params  />} />
                            <Route path="company" element={<Companies params />} />
                            <Route path="course" element={<Course params />} />
                            <Route path="interest" element={<Interest params />} />
                            <Route path="category" element={<Category params />} />
                            <Route path="question" element={<Question params />} />
                            <Route path="skills" element={<Skills params />} />
                            <Route path="blackList" element={<BlackList params />} />
                            <Route path="testAccount" element={<TestAccount params />} />
                            <Route path="learnPlatForm" element={<LearnPlatForm params />} />
                            <Route path="career" element={<Career params />} />
                            <Route path="potentialUsers" element={<PotentialUsers params />} />
                            
                        </Routes>
                    </div>
                </div>
            </div >
        )
    }


    changeColor = (id: any) => {
        this.setState({ selected: id });
    };
    changeChildView(childView: string, id: any) {
        this.setState({ childView, selected: id });
        this.props.navigate(childView)
    }
    alertClicked() {
        alert('You clicked the third ListGroupItem');
    }
    logOut() {
        localStorage.clear();
        this.props.navigate('/')
    }
    setShowPopup() {
        this.setState({ showPopup: !this.state.showPopup })
    }
}


// eslint-disable-next-line import/no-anonymous-default-export
export default function (props: DashboardProps) {
    const navigate = useNavigate();
    return <Dashboard {...props} navigate={navigate} />;
}

