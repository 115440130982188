import React from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TablePagination from '@mui/material/TablePagination';
import { styled } from '@mui/material/styles';
import { useParams } from 'react-router-dom';
import { AuthService } from '../../service/auth';
import { Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisH, faTimes } from '@fortawesome/free-solid-svg-icons';

import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import { Multiselect } from "multiselect-react-dropdown";

import './companies.css';
import { ConfirmDelete } from '../../utils/Delete';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

export interface CompaniesProps {
    params: any
};

export type CompaniesState = {
    accountsList: []
    page: number
    respData: any
    rowsPerPage: number
    totalCount: number
    modelTemp: boolean
    editMod: boolean
    selectedValues: any
    objectArray: any
    editData: any
    items: any
    value: any
    categoryList: []
    formType: any
    deleteItem: any
    showDeleteView: boolean
};

export class Companies extends React.Component<CompaniesProps, CompaniesState> {
    authService: any = new AuthService()

    constructor(props: any) {
        super(props);
        this.state = {
            accountsList: [],
            page: 0,
            rowsPerPage: 10,
            respData: "",
            totalCount: 10,
            modelTemp: false,
            items: '',
            editMod: false,
            editData: '',
            value: '',
            objectArray: [],
            selectedValues: [],
            categoryList: [],
            formType: 'CREATE',
            deleteItem: {},
            showDeleteView: false,
        };
    }

    rows = [];
    createData(companyId: any, name: any, logo: any, shortDescription: any, hourlyRate: any, category: any,) {
        return {
            companyId, name, logo, shortDescription, hourlyRate, category
        };
    }

    list = [];
    categoryData(displayName: any, categoryId: any) {
        return {
            displayName, categoryId
        };
    }

    openDialogh(row: any, type: string){
        let cData: any = {}
            console.log('UPDATE Log', row, type)
            cData['companyId'] = row.companyId ? row.companyId : ''
            cData['name'] = row.name ? row.name : ''
            cData['shortDescription'] = row.shortDescription ? row.shortDescription : ''
            cData['hourlyRate'] = row.hourlyRate ? row.hourlyRate : ''
            cData['category'] = row.category ? row.category : ''
            cData['categoryId'] = row.category ? row.category.categoryId : ''
            console.log('cData', row)

        this.setState({ editData: cData,editMod: true,formType: type
        })
    }
    closeDialogh() {
        this.setState({
            editMod: false,
            formType: 'CREATE'
        })
    }

    OnSeletDeleteOption(company?: any) {
        this.setState({
            deleteItem: company,
            showDeleteView: true
        })
    }
    handleChangeRowsPerPage = (event: any) => {
        this.setState({ rowsPerPage: event.target.value })
    }

    handleChangePage = (event: any, newPage: number) => {
        this.setState({ page: newPage }, () => {
            this.getCompany();
        })
    }

    handleChange = (event: any, key: any) => {
        var data = this.state.editData
        data[key] = event.target.value
        this.setState({ editData: data })
    }

    getCompany() {
        this.authService.getALLCompanies(this.state.page, this.state.rowsPerPage)
            .then((resp: any) => {
                const aList: any = []
                if (resp.data.data) {
                    resp.data.data.data.forEach((item: any) => {
                        const a = this.createData(item.companyId, item.name, item.logo, item.shortDescription, item.hourlyRate, item.category)
                        aList.push(a)
                    });
                }
                console.log('Data', aList)
                this.setState({ accountsList: aList, totalCount: resp.data.data.totalRecords })
            });
    }

    getCategory() {
        this.authService.getALLCategory(0, 1000)
            .then((resp: any) => {
                const aList: any = []
                if (resp.data.data) {
                    resp.data.data.data.forEach((item: any) => {
                        const a = this.categoryData(item.displayName, item.categoryId)
                        aList.push(a)
                    });
                }
                this.setState({ categoryList: aList, totalCount: resp.data.data.totalRecords })
            });
    }

    componentDidMount() {
        this.getCompany()
        this.getCategory()
    }
   
    onSave() {
        let companyData = this.state.editData
        console.log("Company Details Update", companyData)
        let saveObj: any = {}
        saveObj['name'] = companyData.name
        saveObj['shortDescription'] = companyData.shortDescription
        saveObj['hourlyRate'] = companyData.hourlyRate
        saveObj['categoryId'] = companyData.categoryId
        if (this.state.formType === 'CREATE') {
            this.createCompany(saveObj)
            this.closeDialogh()
            this.getCompany()
        } else {
            saveObj['companyId'] = companyData.companyId
            this.updateCompany(companyData.companyId, saveObj)
        }
    }
    createCompany = (saveObj: any) => {
        this.authService.addCompany(saveObj)
            .then((resp: any) => {
                if (resp.data.message === "ADD_CAREER_SUCCESS") {
                    this.closeDialogh()
                }
            })
        console.log('create dataaa', saveObj)
    }

    updateCompany(companyId: any, uData: any) {
        this.authService.updateCompany(companyId, uData)
            .then((resp: any) => {
                console.log('update Company', resp)
                this.closeDialogh()
                this.getCompany()
            })
    }
    deleteData = () => {
        this.authService.deleteCompany(this.state.deleteItem.companyId)
        .then((resp: any) => {
            console.log('Delete Company List', resp)
            if(resp.status === 200) {
                this.getCompany()
                this.closeDeleteModel()
            }
        })
    }

    closeDeleteModel() {
		this.setState({
			showDeleteView: false,
		})
	}

    onSelect(selectedItem:any) {
        console.log('onSelect', selectedItem[0])
        let uData = this.state.editData
        uData['categoryId'] =  selectedItem[0].categoryId
        this.setState({editMod: uData })
    }
    tableHeader = ["Company ID", "Company Name", "Logo", "Description", "Hourly Rate", "Category", "More"]

    render() {
        return (
            <>
            <div className="col-12">
                    <button className="addbtn text-center mt-2 mb-3" onClick={() => { this.openDialogh('', 'CREATE') }} ><b>Add</b></button>
                </div>
                <div className="col-12 min_table">

                
                <TableContainer component={Paper}>
                    <Table sx={{}} aria-label="customized table">
                        <TableHead>
                            <TableRow>
                            {this.tableHeader.map((row: any) => (
                                <StyledTableCell>{row}</StyledTableCell>
                            ))}
                                {/* <StyledTableCell>Company Name</StyledTableCell>
                                <StyledTableCell>Logo</StyledTableCell>
                                <StyledTableCell>Description</StyledTableCell>
                                <StyledTableCell>Hourly Rate</StyledTableCell>
                                <StyledTableCell>Category</StyledTableCell>
                                <StyledTableCell>More</StyledTableCell> */}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.state.accountsList.map((row: any, index: any) => (
                                <StyledTableRow key={row.companyId}>
                                    <StyledTableCell component="th" scope="row">
                                        {row.companyId}
                                    </StyledTableCell>
                                    <StyledTableCell component="th" scope="row">
                                        {row.name}
                                    </StyledTableCell>
                                    <StyledTableCell component="th" scope="row">
                                        <img className="tableLogo" src={row.logo} alt='' />
                                    </StyledTableCell>
                                    <StyledTableCell component="th" scope="row">
                                        {row.shortDescription}
                                    </StyledTableCell>
                                    <StyledTableCell component="th" scope="row">
                                        {row.hourlyRate}
                                    </StyledTableCell>
                                    <StyledTableCell component="th" scope="row">
                                        {row.category.displayName}<br />
                                        {row.category.name}
                                    </StyledTableCell>
                                    <StyledTableCell align="right">
                                        <Dropdown>
                                            <Dropdown.Toggle id="dropdown-basic" variant="none">
                                                <FontAwesomeIcon icon={faEllipsisH} />
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                <Dropdown.Item onClick={() => { this.openDialogh(row, 'UPDATE') }}><b>Edit</b></Dropdown.Item>
                                                <Dropdown.Item onClick={() => { this.OnSeletDeleteOption(row) }}><b>Delete</b></Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </StyledTableCell>
                                </StyledTableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={this.state.totalCount}
                    rowsPerPage={this.state.rowsPerPage}
                    page={this.state.page}
                    onPageChange={this.handleChangePage}
                    onRowsPerPageChange={this.handleChangeRowsPerPage}
                />
                 {this.state.showDeleteView === true ? ConfirmDelete("Confirm Delete Name!", this.OnSeletDeleteOption.bind(this),
				 this.deleteData.bind(this), this.closeDeleteModel.bind(this)) : null}
                {this.state.editMod && this.editCompanies()}
                </div>
            </>
        )
    }
    editCompanies() {
        return (
            <Modal
                open={this.state.editMod}>
                <div style={{
                    position: "absolute",
                    width: 550,
                    height: 750,
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    background: 'white',
                    borderRadius: '9px',
                    outline: "none"
                }} >
                    <div className="col-12">
                        <label className="col-12 pt-3 pe-4 text-end"><FontAwesomeIcon className="col-1" onClick={() => { this.closeDialogh() }} icon={faTimes} /></label>
                        <Typography variant="h6" id="modal-title" className="pt-4 pb-4 row d-flex justify-content-center text-center sticky-top">
                            <b>{this.state.formType === 'CREATE' ? 'CREATE' : 'UPDATE'} COMPANY</b>
                        </Typography>
                        <div className="col-12 row p-0 m-0  justify-content-center">
                            <span className="col-12 row p-0 m-0  justify-content-center">
                                <label className="text">Name</label>
                                <input className="select1 " value={this.state.editData.name} type="text" onChange={(event) => { this.handleChange(event, 'name') }}></input>
                                <label className="text mt-4">Description</label>
                                <input className="select1 " type="text" value={this.state.editData.shortDescription} onChange={(event) => { this.handleChange(event, 'shortDescription') }}></input>
                                <label className="text mt-4">Hourly Rate</label>
                                <input className="select1 " type="text" value={this.state.editData.hourlyRate} onChange={(event) => { this.handleChange(event, 'hourlyRate') }}></input>
                                <label className="text mt-4" >Category</label>
                                <div className="multiselect col-12 p-0 m-0 row justify-content-center">
                                    <Multiselect showArrow singleSelect={true} options={this.state.categoryList} isObject={true}
                                        displayValue="displayName" selectedValues={[this.state.editData.category]}
                                        onSelect={(item)=> {
                                            this.onSelect(item)
                                        }} />
                                </div>
                            </span>
                        </div>

                        <div className=" mt-5 row justify-content-center ">
                            <button className="savebutton" onClick={() => { this.onSave() }} disabled={!this.state.editData.name || !this.state.editData.shortDescription }><b>Save</b></button>
                        </div>
                    </div>
                </div>
            </Modal>
        )
    }

}

// eslint-disable-next-line import/no-anonymous-default-export
export default function (props: CompaniesProps) {
    const params = useParams();
    return <Companies {...props} params={params} />;
}
