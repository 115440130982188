import React from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import { useParams } from 'react-router-dom';
import { AuthService } from '../../service/auth';
import TablePagination from '@mui/material/TablePagination';
import { Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisH, faTimes } from '@fortawesome/free-solid-svg-icons';

import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

export interface LearnPlatFormProps {
    params: any
};

export type LearnPlatFormState = {
    accountsList: []
    page: number
    respData: any
    rowsPerPage: number
    totalCount: number
    modelTemp: boolean
    editData: any
    editMod: boolean
    formType: string
};

export class LearnPlatForm extends React.Component<LearnPlatFormProps, LearnPlatFormState> {
    authService: any = new AuthService()
    constructor(props: any) {
        super(props);
        this.state = {
            accountsList: [],
            page: 0,
            rowsPerPage: 10,
            respData: "",
            totalCount: 10,
            modelTemp: false,
            editData: '',
            editMod: false,
            formType: 'CREATE',
        };
    }

    rows = [];
    createData(id: any, name: any, logo: any, link: any) {
        return {
            id, name, logo, link
        };
    }

    openDialogh(row: any, type: string) {
        let cData: any = {}
        console.log('UPDATE Log', row, type)
        console.log('UPDATE Log', row, type)
        cData['id'] = row.id ? row.id : ''
        cData['name'] = row.name ? row.name : ''
        cData['link'] = row.link ? row.link : ''

        console.log('cData', cData)

        this.setState({
            editData: cData,
            editMod: true,
            formType: type
        })
    }

    closeDialogh() {
        this.setState({
            editMod: false,
            formType: 'CREATE'
        })
    }

    closeModal(user?: any) {
        this.setState({
            // delTmp: user,
            modelTemp: !this.state.modelTemp,
        })
    }
    handleChangeRowsPerPage = (event: any) => {
        this.setState({ rowsPerPage: event.target.value })
    }

    handleChangePage = (event: any, newPage: number) => {
        this.setState({ page: newPage }, () => {
            this.getLearnPlatForm();
        })
    }
    handleChange = (event: any, key: any) => {
        var data = this.state.editData
        data[key] = event.target.value
        this.setState({ editData: data })
    }

    addCompayDetails() {

    }

    editModal(row: any) {
        this.setState({
            editData: row,
            editMod: !this.state.editMod,
        })
    }

    getLearnPlatForm() {
        this.authService.getALLLearnPlatForm(this.state.page, this.state.rowsPerPage)
            .then((resp: any) => {
                const aList: any = []
                if (resp.data.data) {
                    resp.data.data.data.forEach((item: any) => {
                        const a = this.createData(item.id, item.name, item.logo, item.link)
                        aList.push(a)
                    });
                }
                console.log("gsfhkjfh", aList)
                this.setState({ accountsList: aList, totalCount: resp.data.data.totalRecords })
            });
    }
    componentDidMount() {
        this.getLearnPlatForm()
    }

    deleteData() {
        
    }

    tableHeader=["Plat Form ID", "Name", "Logo"]
    render() {
        return (
            <>
            <span className="col-6">
                    <button className="addbtn text-center mt-2 mb-3" onClick={() => { this.openDialogh('', 'CREATE') }} ><b>Add</b></button>
                </span>
                <TableContainer component={Paper}>
                    <Table sx={{}} aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                {this.tableHeader.map((row: any) => (
                                     <StyledTableCell>{row}</StyledTableCell>
                                ))}
                               
                                <StyledTableCell align="right">More</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.state.accountsList.map((row: any) => (
                                <StyledTableRow key={row.id}>
                                    <StyledTableCell component="th" scope="row">
                                        {row.id}
                                    </StyledTableCell>
                                    <StyledTableCell component="th" scope="row">
                                        <a style={{ color: '#2D9CDB' }} href={row.link} target="_blank" rel="noreferrer">{row.name}</a>
                                    </StyledTableCell>
                                    <StyledTableCell component="th" scope="row">
                                        <img className="tableLogo" src={row.logo} alt='' />
                                    </StyledTableCell>
                                    <StyledTableCell align="right">
                                        <Dropdown>
                                            <Dropdown.Toggle id="dropdown-basic" variant="none">
                                                <FontAwesomeIcon icon={faEllipsisH} />
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                <Dropdown.Item onClick={() => { this.openDialogh(row, 'UPDATE') }}><b>Edit</b></Dropdown.Item>
                                                <Dropdown.Item onClick={() => { this.closeModal() }}><b>Delete</b></Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </StyledTableCell>

                                </StyledTableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={this.state.totalCount}
                    rowsPerPage={this.state.rowsPerPage}
                    page={this.state.page}
                    onPageChange={this.handleChangePage}
                    onRowsPerPageChange={this.handleChangeRowsPerPage}
                />
                    {/* {this.state.modelTemp && ConfirmDelete("Confirm Delete Platform Details!", this.closeModal.bind(this), this.deleteData.bind(this))} */}
                {this.state.editMod && this.editLearnPlatform()}
            </>
        )
    }
    editLearnPlatform() {
        return(
            <Modal
            open={this.state.editMod}>
            <div style={{
                position: "absolute",
                width: 550,
                height: 550,
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                background: 'white',
                borderRadius: '9px',
                outline: "none"
            }} >
                <div className="col-12">
                    <label className="col-12 pt-3 pe-4 text-end"><FontAwesomeIcon className="col-1" onClick={() => { this.editModal('') }} icon={faTimes} /></label>
                    <Typography variant="h6" id="modal-title" className="pt-4 pb-4 row d-flex justify-content-center text-center sticky-top">
                        <b>{this.state.formType === 'CREATE' ? 'CREATE' : 'UPDATE'} PLATFORM</b>
                    </Typography>
                    <div className="col-12 row p-0 m-0  justify-content-center">
                        <span className="col-12 row p-0 m-0  justify-content-center">
                            <label className="text">Name</label>
                            <input className="select1 " value={this.state.editData.name} type="text" onChange={(event) => { this.handleChange(event, 'name') }}></input>
                            <label className="text mt-4">Link</label>
                            <input className="select1 " type="text" value={this.state.editData.link} onChange={(event) => { this.handleChange(event, 'link') }}></input>
                        </span>
                    </div>

                    <div className=" mt-5 row justify-content-center ">
                        <button className="savebutton" onClick={() => { this.addCompayDetails() }} disabled={!this.state.editData.name || !this.state.editData.link }><b>Save</b></button>
                    </div>
                </div>
            </div>
        </Modal>

        )
    }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function (props: LearnPlatFormProps) {
    const params = useParams();
    return <LearnPlatForm {...props} params={params} />;
}

