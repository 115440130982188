import Moment from 'moment';

export class Pipe {
  date: any
  time: any
  dateform: any

  dateFormat(date: any) {
    return Moment(date).format('DD MMM YY, hh:mm A');
  }

  timeFormat(date: any) {
    return Moment(date).format('hh:mm A');
  }

  dateForm(dateform: any) {
    return Moment(dateform).format('DD MMM YY');
  }

  convertMinToFormatedString(min: any) {
    let seconds = min * 60 || 0;
    seconds = Number(seconds);
    seconds = Math.abs(seconds);

    var d = Math.floor(seconds / (3600 * 24));
    var h = Math.floor((seconds % (3600 * 24)) / 3600);
    var m = Math.floor((seconds % 3600) / 60);
    var s = Math.floor(seconds % 60);
    var mostPopularFGoalType: any = [];

    if (d > 0) {
      var dDisplay = d > 0 ? d + " " + (d === 1 ? "day" : "days") : "";
      mostPopularFGoalType.push(dDisplay);
    }

    if (h > 0) {
      var hDisplay = h > 0 ? h + " " + (h === 1 ? "hour" : "hours") : "";
      mostPopularFGoalType.push(hDisplay);
    }

    if (m > 0) {
      var mDisplay = m > 0 ? m + " " + (m === 1 ? "minute" : "minutes") : "";
      mostPopularFGoalType.push(mDisplay);
    }

    if (s > 0) {
      var sDisplay = s > 0 ? s + " " + (s === 1 ? "second" : "seconds") : "";
      mostPopularFGoalType.push(sDisplay);
    }

    return mostPopularFGoalType.join(", ");
  }
}
