/**
 * @throws Will throw an error if process.env.NODE_ENV 
 *   is an unknown value
 */
const isDev = () => {
  // dev or test environment
  if (
    window.location.hostname === 'admin.gigtirbo.com'
  ) {
    return false;
  }

  // production environment
  // if (process.env.NODE_ENV === 'production') {
    return true;
  // }

  // none of the above
  // throw new Error("process.env.NODE_ENV returns unknown value");

}

export default isDev;