import React from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import { useParams } from 'react-router-dom';
import { AuthService } from '../../service/auth';
import TablePagination from '@mui/material/TablePagination';
import { Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisH, faPlusCircle, faTimes, faTimesCircle } from '@fortawesome/free-solid-svg-icons';

import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';

import './question.css'

import { ConfirmDelete } from '../../utils/Delete';
// import { Prev } from 'react-bootstrap/esm/PageItem';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
	[`&.${tableCellClasses.head}`]: {
		backgroundColor: theme.palette.common.black,
		color: theme.palette.common.white,
	},
	[`&.${tableCellClasses.body}`]: {
		fontSize: 14,
	},
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
	'&:nth-of-type(odd)': {
		backgroundColor: theme.palette.action.hover,
	},
	'&:last-child td, &:last-child th': {
		border: 0,
	},
}));

export interface QuestionProps {
	params: any
};

export type QuestionState = {
	questionsList: []
	page: number
	respData: any
	rowsPerPage: number
	totalCount: number
	modelTemp: boolean
	editData: any
	editMod: boolean
	inputs: any
	clear: any
	showDeleteView: boolean
	deleteItem: any
};

export class Question extends React.Component<QuestionProps, QuestionState> {
	authService: any = new AuthService()
	constructor(props: any) {
		super(props);
		this.state = {
			questionsList: [],
			page: 0,
			rowsPerPage: 10,
			respData: "",
			totalCount: 10,
			modelTemp: false,
			editData: '',
			editMod: false,
			inputs: [],
			clear: [],
			showDeleteView: false,
			deleteItem: {},
		};
	}

	rows = [];
	createData(id: any, question: any, options: any) {
		return {
			id, question, options
		};
	}

	closeModal(user?: any) {
		this.setState({
			modelTemp: !this.state.modelTemp,
		})
	}
	handleChangeRowsPerPage = (event: any) => {
		this.setState({ rowsPerPage: event.target.value })
	}

	handleChangePage = (event: any, newPage: number) => {
		this.setState({ page: newPage }, () => {
			this.getQuestion();
		})
	}
	getQuestion() {
		this.authService.getALLQuestion(this.state.page, this.state.rowsPerPage)
			.then((resp: any) => {
				const aList: any = []
				if (resp.data.data) {
					resp.data.data.data.forEach((item: any) => {
						const a = this.createData(item.id, item.question, item.options)
						aList.push(a)
					});
				}
				this.setState({ questionsList: aList })
			});
	}
	componentDidMount() {
		this.getQuestion()
	}
	editModal(row: any) {

		this.setState({
			editData: row,
			editMod: !this.state.editMod,
		})
	}

	updateQuestions() {
		
		let updateData = this.state.editData
		console.log('updateData', updateData)
		let newOptionArray: any = []
		updateData.options.map((item: any) => {
			return(
			newOptionArray.push({ option: item.option })
			)
		})
		updateData.options = newOptionArray
		let dataa = {
			name: '',
			options: []
		}
		dataa.name = updateData.question
		dataa.options = updateData.options
		console.log('updateData modified ',updateData.id, dataa)
		this.authService.updateQuestion(updateData.id, dataa)
		.then((resp: any) => {
			console.log('update Questions', resp)
			this.editModal('')
			this.getQuestion()
		})
	}

	handleChange = (event: any, key: any) => {
		var data = this.state.editData
		data[key] = event.target.value
		this.setState({ editData: data })
	}

	addInput = (ev: any) => {
		let uuid1 = Math.floor(Math.random() * 100000 + 1)
		this.state.editData.options.push({ id: `${uuid1}`, option: '' })
		console.log('options List', this.state.editData.options)
		this.setState({ editMod: true })
		this.editQuestion()

	}

	deleteOption(id: any) {
		let dataaa = this.state.editData.options.filter((it: any) => it.id === id)
		this.state.editData.options.splice(this.state.editData.options.findIndex((v: any) => v.id === dataaa[0].id), 1);
		this.setState({ editMod: true })
		this.editQuestion()
	}

	deleteData() {
		
	}

	OnSeletDeleteOption(black?: any) {
        this.setState({
            deleteItem: black,
            showDeleteView: true
        })
    }

	closeDeleteModel() {
		this.setState({
			showDeleteView: false,
		})
	}
	tableHeader = ["Question ID", "Questions", " Options", "More"]
	render() {
		return (
			<>
				<TableContainer component={Paper}>
					<Table sx={{}} aria-label="customized table">
						<TableHead>
							<TableRow>
								{this.tableHeader.map((row: any) => (
									<StyledTableCell>{row}</StyledTableCell>
								))}
							</TableRow>
						</TableHead>
						<TableBody>
							{this.state.questionsList.map((row: any) => (
								<StyledTableRow key={row.id}>
									<StyledTableCell component="th" scope="row">
										{row.id}
									</StyledTableCell>
									<StyledTableCell component="th" scope="row">
										{row.question}
									</StyledTableCell>
									<StyledTableCell component="th" scope="row">
										{row.options.map((row1: any) => (
											<label key={row1.id} className="col-12">{row1.option}</label>
										))}
									</StyledTableCell>
									<StyledTableCell align="right">
										<Dropdown>
											<Dropdown.Toggle id="dropdown-basic" variant="none">
												<FontAwesomeIcon icon={faEllipsisH} />
											</Dropdown.Toggle>
											<Dropdown.Menu>
												<Dropdown.Item onClick={() => { this.editModal(row) }}><b>Edit</b></Dropdown.Item>
												<Dropdown.Item onClick={() => { this.OnSeletDeleteOption(row) }}><b>Delete</b></Dropdown.Item>
											</Dropdown.Menu>
										</Dropdown>
									</StyledTableCell>
								</StyledTableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
				<TablePagination
					rowsPerPageOptions={[5, 10, 25]}
					component="div"
					count={this.state.totalCount}
					rowsPerPage={this.state.rowsPerPage}
					page={this.state.page}
					onPageChange={this.handleChangePage}
					onRowsPerPageChange={this.handleChangeRowsPerPage}
				/>
				 {this.state.showDeleteView === true ? ConfirmDelete("Confirm Delete Name!", this.OnSeletDeleteOption.bind(this),
				 this.deleteData.bind(this), this.closeDeleteModel.bind(this)) : null}
				{this.state.editMod && this.editQuestion()}
			</>
		)
	}
	editQuestion() {
		return (
			<Modal
				open={this.state.editMod}>
				<div style={{
					position: "absolute",
					width: 550,
					height: 750,
					top: '50%',
					left: '50%',
					transform: 'translate(-50%, -50%)',
					background: 'white',
					borderRadius: '9px',
					outline: "none"
				}} >
					{this.state.editMod && <div className="col-12" >
						<label className="col-12 pt-3 pe-4 text-end"><FontAwesomeIcon className="col-1" onClick={() => { this.editModal('') }} icon={faTimes} /></label>

						<Typography variant="h6" id="modal-title" className="pt-4 pb-4 row d-flex justify-content-center text-center sticky-top">
							<b>Edit Question Details! {this.state.editData.name}</b>
						</Typography>
						<div>
							<span className="col-12 row p-0 m-0  justify-content-center">
								<label className="text">Questions</label>
								<input className="select1 " value={this.state.editData.question} type="text" onChange={(event) => { this.handleChange(event, 'question') }}></input>
							</span>
							<label className="text mt-4">Options</label>
							<label className="ms-5 me-5">  <FontAwesomeIcon onClick={this.addInput} icon={faPlusCircle} /></label>
							<span className="col-12 row p-0 m-0  justify-content-center">
								{this.state.editMod && this.state.editData.options.map((item: any) => {
									return (
										<div className="col-12 d-flex flex-direction-row justify-content-center">
											<input key={item.id} className="select2 m-1" value={item.option} onChange={(event) => this.onOptionsChange(item.id, event)}></input>
											<label className="mt-3"><FontAwesomeIcon onClick={(any) => {
												this.deleteOption(item.id)
											}} icon={faTimesCircle} /></label>
										</div>)
								})}
							</span>
						</div>
						<div className=" mt-5 row justify-content-center ">
							<button className="savebutton" onClick={() => { this.updateQuestions() }}><b>Save</b></button>
						</div>
					</div>}
				</div>
			</Modal>
		)
	}

	onOptionsChange(id: any, event: any) {
		let data = this.state.editData
		// let dataOptions = data['options']
		let optionData = data.options.filter((it: any) => it.id === id)
		optionData[0].option = event.target.value
		this.setState({ editData: data })
	}
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function (props: QuestionProps) {
	const params = useParams();
	return <Question {...props} params={params} />;
}
