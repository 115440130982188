import React from "react";

import { useNavigate } from "react-router-dom";
// import DatePicker from "react-date-picker";
import { AuthService } from "../../service/auth";

import "./mainDashboard.css";
import { Paper } from "@mui/material";
import moment from "moment";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
// import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";
import { Pipe } from '../../pipes/pipe';
import 'react-datepicker/dist/react-datepicker-cssmodules.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

// import TextField from '@mui/material/TextField';
// import AdapterDateFns from '@mui/lab/AdapterDateFns';
// import LocalizationProvider from '@mui/lab/LocalizationProvider';
// import DatePicker from '@mui/lab/DatePicker';
// import MobileDatePicker from '@mui/lab/MobileDatePicker';
// import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
// import Stack from '@mui/material/Stack';

const StyledTableCell = styled(TableCell)(() => ({}));

const StyledTableRow = styled(TableRow)(() => ({}));

export type MainDashboardProps = {
  navigate: any;
};

export type MainDashboardState = {
  eDate: any;
  sDate: any;
  goalData: any;
  topCategory: [];
  topCompany: [];
  topCourse: [];
};

export class MainDashboard extends React.Component<
  MainDashboardProps,
  MainDashboardState
> {
  authService: any = new AuthService();
  pipe: any = new Pipe()
  constructor(props: any) {
    super(props);

    this.state = {
      // eDate: moment(),
      // sDate: moment().subtract(30, "days"),
      eDate: new Date(),
      sDate: new Date(new Date().setDate(new Date().getDate() - 30)),
      goalData: {},
      topCategory: [],
      topCompany: [],
      topCourse: [],
    };
  }

  rows = [];
  createData(id: any, name: any, displayName: any) {
    return {
      id,
      name,
      displayName,
    };
  }

  cList = [];
  companyData(name: any, hourlyRate: any) {
    return {
      name,
      hourlyRate,
    };
  }

  courseData(id: any, name: any, duration: any) {
    return {
      id,
      name,
      duration,
    };
  }

  onChangeDate(event: any, key: any) {
    console.log("onChangeDate ", event);
    if (key === "eDate") {
      this.setState({ eDate: new Date(event) }, () => {
        this.getGoalData();
      });
    } else {
      this.setState({ sDate: new Date(event) }, () => {
        this.getGoalData();
      });
    }
  }

  getGoalData() {
    this.authService
      .getDashBoardGoal({
        startDate:
          moment(this.state.sDate).format("YYYY-MM-DDTHH:mm:ss.sss") + "Z",
        endDate:
          moment(this.state.eDate).format("YYYY-MM-DDTHH:mm:ss.sss") + "Z",
      })
      .then((resp: any) => {
        if (resp.data.data) {
        }
        console.log("gsfhkjfh");
        let goalData = { ...resp.data.data[0] };
        this.setState({ goalData });
      });
  }

  getTopCategory() {
    this.authService.getDashBoardTopCategory().then((resp: any) => {
      const aList: any = [];
      if (resp.data.data) {
        resp.data.data.forEach((item: any) => {
          const a = this.createData(item.id, item.name, item.displayName);
          aList.push(a);
        });
      }
      console.log("Top Category", aList);
      this.setState({ topCategory: aList });
    });
  }

  getTopCompany() {
    this.authService.getDashBoardTopCompany().then((resp: any) => {
      const cList: any = [];
      if (resp.data.data) {
        resp.data.data.forEach((item: any) => {
          const a = this.companyData(item.name, item.hourlyRate);
          cList.push(a);
        });
      }
      console.log("Top Company", cList);
      this.setState({ topCompany: cList });
    });
  }

  getALLPopularCourse() {
    this.authService.getDashBoardPopularCourse().then((resp: any) => {
      const pList: any = [];
      if (resp.data.data) {
        resp.data.data.forEach((item: any) => {
          const a = this.courseData(item.id, item.name, item.duration);
          pList.push(a);
        });
      }
      console.log("Popular Course", pList);
      this.setState({ topCourse: pList });
    });
  }
  componentDidMount() {
    console.log("componentDidMount", this.state.sDate, this.state.eDate);
    this.getGoalData();
    this.getTopCategory();
    this.getTopCompany();
    this.getALLPopularCourse();
  }
  render() {
    return (
      <div className="ms-4 mt-4">
        <div className="row col-12 " >
          <div className="col-6">
            <h3 className="mb-4" style={{ float: "left" }}>
              Dashboard
            </h3>
          </div>

          <div className="col-6 d-flex justify-content-end pe-0 py-2" >
            <span className="me-2">

              <DatePicker className="datepicker"
                selected={this.state.sDate}
                dateFormat="dd/MM/yyyy"
                onChange={(event: any) => {
                  this.onChangeDate(event, "sDate");
                }} 
              />
             
            </span>
            <span className="ms-2">
              <DatePicker className="datepicker"
                dateFormat="dd/MM/yyyy"
                selected={this.state.eDate}
                onChange={(event: any) => {
                  this.onChangeDate(event, "eDate");
                }}
              />
            </span>
          </div>
        </div>

        {/* <span className="col-12"> */}
        <div className="col-12 row m-0 p-0 my-2 mb-5 text-center">
          <div className="col-12 col-sm-6 col-md-3 p-2">
            <Paper className="paperStyle">
              <b style={{ fontSize: "20px" }}>
                {this.pipe.convertMinToFormatedString(
                  this.state.goalData.targetLearn
                )}
              </b>{" "}
              <br></br>
              <p className="text-muted">Target Learn</p>
            </Paper>
          </div>
          <div className="col-12 col-sm-6 col-md-3 p-2">
            <Paper className="paperStyle">
              <b style={{ fontSize: "20px" }}>
              {this.pipe.convertMinToFormatedString(
                this.state.goalData.targetProgressLearn
              )}
              </b>{" "}
              <br></br>
              <p className="text-muted">Target Progress Learn </p>
            </Paper>
          </div>
          <div className="col-12 col-sm-6 col-md-3 p-2">
            <Paper className="paperStyle">
              <b style={{ fontSize: "20px" }}>
               $ { this.state.goalData.targetEarnFinite }
              </b>{" "}
              <br></br>
              <p className="text-muted">Target Earn Finite</p>
            </Paper>
          </div>
          <div className="col-12 col-sm-6 col-md-3 p-2">
            <Paper className="paperStyle">
              <b style={{ fontSize: "20px" }}>
                $ {this.state.goalData.targetProgressEarnFinite}
              </b>{" "}
              <br></br>
              <p className="text-muted">Target Progress Earn Finite </p>
            </Paper>
          </div>
        </div>
        <div className="col-12 row m-0 pb-4 text-center">
          <div className="col-12 col-sm-6 col-md-3 p-2">
            <Paper className="paperStyle">
              <b style={{ fontSize: "20px" }}>
                $ {this.state.goalData.targetEarnWeekly}
              </b>{" "}
              <br></br>
              <p className="text-muted">Target Earn Weekly</p>
            </Paper>
          </div>
          <div className="col-12 col-sm-6 col-md-3 p-2">
            <Paper className="paperStyle">
              <b style={{ fontSize: "20px" }}>
                $ {this.state.goalData.targetProgressEarnWeekly}
              </b>{" "}
              <br></br>
              <p className="text-muted">Target Progress Earn Weekly </p>
            </Paper>
          </div>
          <div className="col-12 col-sm-6 col-md-3 p-2">
            <Paper className="paperStyle">
              <b style={{ fontSize: "20px" }}>
                $ {this.state.goalData.targetEarnMonthly}
              </b>{" "}
              <br></br>
              <p className="text-muted">Target Earn Monthly </p>
            </Paper>
          </div>
          <div className="col-12 col-sm-6 col-md-3 p-2">
            <Paper className="paperStyle">
              <b style={{ fontSize: "20px" }}>
               $ {this.state.goalData.targetProgressEarnMonthly}
              </b>{" "}
              <br></br>
              <p className="text-muted">Target Progress Earn Monthly </p>
            </Paper>
          </div>
        </div>

        {/* </span> */}

        <div className="col-12 row p-0 m-0 pt-4 d-flex justify-content-center">
          <div className="col-6 col-md-2 p-2">
            <Paper className="paperStyle">
              <b style={{ fontSize: "20px" }}>
                {this.state.goalData.mostPopularType}
              </b>{" "}
              <br></br>
              <p className="text-muted">Most popular Goal Type</p>
            </Paper>
          </div>
          <div className="col-6 col-md-2 p-2">
            <Paper className="paperStyle">
              <b style={{ fontSize: "20px" }}>
                {this.state.goalData.mostPoplarFinancialType}
              </b>{" "}
              <br></br>
              <p className="text-muted">Most popular Financial Goal Type</p>
            </Paper>
          </div>
          <div className="col-6 col-md-2 p-2">
            <Paper className="paperStyle">
              <b style={{ fontSize: "25px" }}>
                ${this.state.goalData.avgTargetFinancialRecurring}
              </b>{" "}
              <br></br>
              <p className="text-muted">AVG Reoccuring goal amounts</p>
            </Paper>
          </div>
          <div className="col-6 col-md-2 p-2">
            <Paper className="paperStyle">
              <b style={{ fontSize: "25px" }}>
                ${this.state.goalData.avgTargetFinancialFinite}
              </b>{" "}
              <br></br>
              <p className="text-muted">
                AVG One-Time goal amounts (money amount)
              </p>
            </Paper>
          </div>
          <div className="col-6 col-md-2 p-2">
            <Paper className="paperStyle">
              <b style={{ fontSize: "20px" }}>
                {this.pipe.convertMinToFormatedString(
                  this.state.goalData.avgDurationLearn
                )}
              </b>{" "}
              <br></br>
              <p className="text-muted">AVG One-Time goal duration Learn</p>
            </Paper>
          </div>
        </div>

        <div className="col-12 row p-0 m-0 d-flex justify-content-center">
          <div className="col-6 col-md-2 p-2">
            <Paper className="paperStyle">
              <b style={{ fontSize: "20px" }}>
                {this.pipe.convertMinToFormatedString(
                  this.state.goalData.avgDurationFinite
                )}
              </b>{" "}
              <br></br>
              <p className="text-muted">AVG One-Time goal duration Earn</p>
            </Paper>
          </div>
          <div className="col-6 col-md-2 p-2">
            <Paper className="paperStyle">
              <b style={{ fontSize: "25px" }}>
              {this.pipe.convertMinToFormatedString(
                this.state.goalData.avgAvailableTimePerWeekLearn
              )}
              </b>{" "}
              <br></br>
              <p className="text-muted">AVG learn, show AVG hour/week</p>
            </Paper>
          </div>
          <div className="col-6 col-md-2 p-2">
            <Paper className="paperStyle">
              <b style={{ fontSize: "25px" }}>
              {this.pipe.convertMinToFormatedString(
                this.state.goalData.avgAvailableTimePerWeekEarnFinite
              )}
              </b>{" "}
              <br></br>
              <p className="text-muted">
                {" "}
                AVG earn-one-time, show AVG hour/week
              </p>
            </Paper>
          </div>
          <div className="col-6 col-md-2 p-2">
            <Paper className="paperStyle">
              <b style={{ fontSize: "25px" }}>
                {this.pipe.convertMinToFormatedString(
                  this.state.goalData.avgAvailableTimePerWeekEarnWeekly
                  )}
              </b>{" "}
              <br></br>
              <p className="text-muted">AVG earn-weekly, show AVG hour/week</p>
            </Paper>
          </div>
          <div className="col-6 col-md-2 p-2">
            <Paper className="paperStyle">
              <b style={{ fontSize: "25px" }}>
              {this.pipe.convertMinToFormatedString(
                this.state.goalData.avgAvailableTimePerWeekEarnMonthly
              )}
              </b>{" "}
              <br></br>
              <p className="text-muted">
                {" "}
                AVG earn-monthly, show AVG hour/week
              </p>
            </Paper>
          </div>
          {/* <div className="col-6 col-md-2">
                        <Paper className="paperStyle">
                            <b style={{ fontSize: "25px" }}>{this.state.goalData.mostPopularFGoalType}</b> <br></br>
                            <p className="text-muted">How many cancelled/deleted events (AVG) (use event log)</p>
                        </Paper>
                    </div>
                    <div className="col-6 col-md-2">
                        <Paper className="paperStyle">
                            <b style={{ fontSize: "25px" }}>{this.state.goalData.mostPopularFGoalType}</b> <br></br>
                           <p className="text-muted"> Time Spend on platform (GTM)</p>
                        </Paper>
                    </div> */}
          {/* <div className="col-6 col-md-2">
                        <Paper className="paperStyle">
                            <b style={{fontSize: "30px"}}>{this.state.goalData.mostPopularFGoalType}</b> <br></br>
                            Top 10 courses picked by users
                        </Paper>
                    </div> */}
        </div>

        <div className="row my-2 mb-5 col-12">
          {/* <div className="col-6 col-md-2">
                        <Paper className="paperStyle">
                            <b style={{fontSize: "30px"}}>{this.state.goalData.mostPopularFGoalType}</b> <br></br>
                            Most popular gig platforms selected by users
                        </Paper>
                    </div>
                    <div className="col-6 col-md-2">
                        <Paper className="paperStyle">
                            <b style={{fontSize: "30px"}}>{this.state.goalData.mostPopularFGoalType}</b> <br></br>
                            Most popular career paths (freelancer apis)
                        </Paper>
                    </div>
                    <div className="col-6 col-md-2">
                        <Paper className="paperStyle">
                            <b style={{fontSize: "30px"}}>{this.state.goalData.mostPopularFGoalType}</b> <br></br>
                            Most popular categories chosen (top 10)
                        </Paper>
                    </div> */}

          <div className="row col-12 m-0 p-0 mx-0 my-2 mb-5 justify-content-center">
            <div className="col-12 col-md-6 col-lg-4 py-2 ">
              <Paper className="paper">
                {/* <TableContainer component={Paper}> */}
                <Table>
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>
                        <b className="text-muted">Top Category</b>
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {this.state.topCategory.map((row: any, index: any) => (
                      <StyledTableRow key={row.id}>
                        {index !== this.state.topCategory.length - 1 ? (
                          <StyledTableCell className="text-dark">
                            <label className="text-dark">{row.name}</label>
                          </StyledTableCell>
                        ) : (
                          <TableCell>
                            <label className="text-dark">{row.name}</label>{" "}
                          </TableCell>
                        )}
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
                {/* </TableContainer> */}
              </Paper>
            </div>

            <div className="col-12 col-md-6 col-lg-4 py-2">
              <Paper className="paper">
                <Table>
                  <TableHead>
                    <TableRow className="text-muted">
                      <StyledTableCell>
                        <b className="text-muted">Top Company</b>
                      </StyledTableCell>
                      <StyledTableCell>
                        <b className="text-muted">Hourly Rate</b>
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {this.state.topCompany.map((row: any) => (
                      <StyledTableRow key={row.name}>
                        <StyledTableCell>
                          <label className="text-dark">{row.name}</label>
                        </StyledTableCell>
                        <StyledTableCell>
                          <label className="text-dark"> ${row.hourlyRate} / hour</label>
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </Paper>
            </div>

            <div className="col-12 col-md-6 col-lg-4 pe-0 py-2">
              <Paper className="paper">
                <Table>
                  <TableHead>
                    <TableRow >
                      <StyledTableCell>
                        <b className="text-muted">Popular Course</b>
                      </StyledTableCell>
                      <StyledTableCell>
                        <b className="text-muted">Duration</b>
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.topCourse.map((row: any) => (
                      <StyledTableRow key={row.name}>
                        <StyledTableCell>
                          <label className="">{row.name}</label>
                        </StyledTableCell>
                        <StyledTableCell>
                          <label className="">{row.duration} Hours</label>
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </Paper>
            </div>
          </div>

        </div>
      </div>
    );
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function (props: MainDashboardProps) {
  const navigate = useNavigate();
  return <MainDashboard {...props} navigate={navigate} />;
}
