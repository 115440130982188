import React from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import { useParams } from 'react-router-dom';
import { AuthService } from '../../service/auth';
import TablePagination from '@mui/material/TablePagination';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisH, faTimes } from '@fortawesome/free-solid-svg-icons';

import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import { Multiselect } from "multiselect-react-dropdown";

import './course.css'
import { ConfirmDelete } from '../../utils/Delete';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

export interface CourseProps {
    params: any
};

export type CourseState = {
    accountsList: []
    page: number
    respData: any
    rowsPerPage: number
    totalCount: number
    isLoading: boolean
    modelTemp: boolean
    selectedValues: any
    objectArray: any
    editMod: boolean
    editData: any
    careersList: []
    formType: string
    deleteItem: any
    showDeleteView: boolean
};

export class Course extends React.Component<CourseProps, CourseState> {
    authService: any = new AuthService()

    constructor(props: any) {
        super(props);
        this.state = {
            accountsList: [],
            page: 0,
            rowsPerPage: 10,
            respData: "",
            totalCount: 10,
            isLoading: true,
            modelTemp: false,
            editMod: false,
            objectArray: [],
            selectedValues: [],
            editData: '',
            careersList: [],
            formType: 'CREATE',
            deleteItem: {},
            showDeleteView: false,
        };
    }

    rows = [];
    createData(courseId: any, courseName: any, cost: any, duration: any, link: any, careers: any) {
        return {
            courseId, courseName, cost, duration, link, careers
        };
    }

    list = [];
    careerData(name: any, id: any) {
        return {
            name, id

        }
    }
    openDialogh(row: any, type: string){
        let cData: any = {}
        console.log('UPDATE Log', row, type)
        cData['courseId'] = row.courseId ? row.courseId : ''
        cData['courseName'] = row.courseName ? row.courseName : ''
        cData['duration'] = row.duration ? row.duration : ''
        cData['cost'] = row.cost ? row.cost : ''
        cData['careers'] = row.careers ? row.careers : ''
        cData['careers'] = row.careers ? row.careers[0].id : ''
        console.log('cData', cData)

    this.setState({
        editData: cData,
        editMod: true,
        formType: type
    })
    }

    closeDialogh() {
        this.setState({
            editMod: false,
            formType: 'CREATE'

        })
    }

    OnSeletDeleteOption(course?: any) {
        this.setState({
            deleteItem: course,
            showDeleteView: true
        })
    }

    handleChangeRowsPerPage = (event: any) => {
        this.setState({ rowsPerPage: event.target.value })
    }

    handleChangePage = (event: any, newPage: number) => {
        this.setState({ page: newPage }, () => {
            this.getCourse();
        })
    }

    handleChange = (event: any, key: any) => {
        var data = this.state.editData
        data[key] = event.target.value
        this.setState({ editData: data })
    }

    getCourse() {
        this.authService.getALLCourse(this.state.page, this.state.rowsPerPage)
            .then((resp: any) => {
                const aList: any = []
                if (resp.data.data) {
                    this.setState({ isLoading: false })
                    resp.data.data.data.forEach((item: any) => {
                        const a = this.createData(item.courseId, item.courseName, item.cost, `${item.duration} Hours`, item.link, item.careers)
                        aList.push(a)
                    });
                }
                console.log("vg",aList)
                this.setState({ accountsList: aList, totalCount: resp.data.data.totalRecords })
            });
    }

    getCareer() {
        this.authService.getALLCareer(0, 1000)
            .then((resp: any) => {
                const aList: any = []
                if (resp.data.data) {
                    resp.data.data.data.forEach((item: any) => {
                        const a = this.careerData(item.name, item.id)
                        aList.push(a)
                    });
                }
                this.setState({ careersList: aList, totalCount: resp.data.data.totalRecords })
            });
    }

    componentDidMount() {
        this.getCourse()
        this.getCareer()
    }

    UserGreeting() {
        return <h1>Welcome back!</h1>;
    }

    GuestGreeting() {
        return <h1>Please sign up.</h1>;
    }
   
    onSave() {
        let courseData = this.state.editData
        console.log("Course Details Update", courseData)
        let saveObj: any = {}
        saveObj['courseName'] = courseData.courseName
        saveObj['duration'] = courseData.duration
        saveObj['cost'] = courseData.cost
        saveObj['id'] = courseData.id
        if (this.state.formType === 'CREATE') {
            this.createCourse(saveObj)
            this.getCourse()
            this.closeDialogh()
        } else {
            saveObj['courseId'] = courseData.courseId
            this.updateCourse(courseData.courseId, saveObj)
        }
    }

    createCourse = (saveObj: any) => {
        this.authService.addCourse(saveObj)
            .then((resp: any) => {
                if (resp.data.message === "ADD_COURSE_SUCCESS") {
                    this.closeDialogh()
                }
            })
        console.log('create dataaa', saveObj)
    }

    updateCourse(courseId: any, uData: any) {
        this.authService.updateCourse(courseId, uData)
            .then((resp: any) => {
                console.log('update Course', resp)
                this.closeDialogh()
                this.getCourse()
            })
    }
    deleteData = () => {
        this.authService.deleteCourse(this.state.deleteItem.courseId)
        .then((resp: any) => {
            console.log('Delete Course List', resp)
            if(resp.status === 200) {
                this.getCourse()
                this.closeDeleteModel()
            }
        })
    }

    closeDeleteModel() {
		this.setState({
			showDeleteView: false,
		})
	}

    onSelect(selectedItem:any) {
        console.log('onSelect', selectedItem[0])
        let uData = this.state.editData
        uData['id'] =  selectedItem[0].id
        this.setState({editMod: uData })
    }
tableHeader = ["Course ID", "Course Name", "Rate", "Duration", "Careers", "More"]
    render() {
        return (
            <>
            <span className="col-6">
                    <button className="addbtn text-center mt-2 mb-3" onClick={() => { this.openDialogh('', 'CREATE') }} ><b>Add</b></button>
                </span>
                <TableContainer component={Paper}>
                    <Table sx={{}} aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                {this.tableHeader.map((row: any) => (
                                <StyledTableCell>{row}</StyledTableCell>
                                ))}
                                {/* <StyledTableCell>Course Name</StyledTableCell>
                                <StyledTableCell>Rate</StyledTableCell>
                                <StyledTableCell>Duration</StyledTableCell>
                                <StyledTableCell>Careers</StyledTableCell>
                                <StyledTableCell align="right">More</StyledTableCell> */}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.state.accountsList.map((row: any) => (
                                <StyledTableRow key={row.courseId}>
                                    <StyledTableCell component="th" scope="row">
                                        {row.courseId}
                                    </StyledTableCell>
                                    <StyledTableCell component="th" scope="row">
                                        <a style={{ color: '#2D9CDB' }} href={row.link} target="_blank" rel="noreferrer">{row.courseName}</a>
                                    </StyledTableCell>
                                    <StyledTableCell component="th" scope="row">
                                        {row.cost}
                                    </StyledTableCell>
                                    <StyledTableCell component="th" scope="row">
                                        {row.duration}
                                    </StyledTableCell>
                                    <StyledTableCell component="th" scope="row">
                                        {row.careers[0].name}<br />
                                        {row.careers[0].earnings}
                                    </StyledTableCell>
                                    <StyledTableCell align="right">
                                        <Dropdown>
                                            <Dropdown.Toggle id="dropdown-basic" variant="none">
                                                <FontAwesomeIcon icon={faEllipsisH} />
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                <Dropdown.Item onClick={() => { this.openDialogh(row, 'UPDATE') }}><b>Edit</b></Dropdown.Item>
                                                <Dropdown.Item onClick={() => { this.OnSeletDeleteOption() }}><b>Delete</b></Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </StyledTableCell>
                                </StyledTableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={this.state.totalCount}
                    rowsPerPage={this.state.rowsPerPage}
                    page={this.state.page}
                    onPageChange={this.handleChangePage}
                    onRowsPerPageChange={this.handleChangeRowsPerPage}
                />
                 {this.state.showDeleteView === true ? ConfirmDelete("Confirm Delete Name!", this.OnSeletDeleteOption.bind(this),
				 this.deleteData.bind(this), this.closeDeleteModel.bind(this)) : null}
                {this.state.editMod && this.editeCourse()}
            </>
        )
    }
    editeCourse() {
        return(
            <Modal
            open={this.state.editMod}>
            <div style={{
                position: "absolute",
                width: 550,
                height: 750,
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                background: 'white',
                borderRadius: '9px',
                outline: "none"
            }} >
                <div className="col-12">
                    <label className="col-12 pt-3 pe-4 text-end"><FontAwesomeIcon className="col-1" onClick={() => { this.closeDialogh() }} icon={faTimes} /></label>
                    <Typography variant="h6" id="modal-title" className="pt-4 pb-4 row d-flex justify-content-center text-center sticky-top">
                        <b>{this.state.formType === 'CREATE' ? 'CREATE' : 'UPDATE'} COURSE</b>
                    </Typography>
                    <div className="col-12 row p-0 m-0  justify-content-center">
                        <label className="text">Course Name</label>
                        <input className="select1 " type="text" value={this.state.editData.courseName} onChange={(event) => { this.handleChange(event, 'courseName') }}></input>
                        <label className="text mt-4">Duration</label>
                        <input className="select1 " type="text" value={this.state.editData.duration} onChange={(event) => { this.handleChange(event, 'duration') }}></input>
                        <label className="text mt-4">Course Rate</label>
                        <input className="select1 " type="text" value={this.state.editData.cost} onChange={(event) => { this.handleChange(event, 'cost') }}></input>
                        <label className="text mt-4">Careers</label>
                    </div>
                    <div className="multiselect col-12 p-0 m-0 row justify-content-center">
                        <Multiselect showArrow options={this.state.careersList} isObject={true}
                            displayValue="name" selectedValues={this.state.editData.careers} 
                            onSelect={(item)=> {
                                this.onSelect(item)
                            }}/>
                    </div>
                    <div className=" mt-5 row justify-content-center ">
                        <button className="savebutton" onClick={() => { this.onSave() }} disabled={!this.state.editData.courseName || !this.state.editData.duration}><b>Save</b></button>
                    </div>
                </div>
            </div>
        </Modal>   
        )
    }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function (props: CourseProps) {
    const params = useParams();
    return <Course {...props} params={params} />;
}
