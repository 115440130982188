import React from 'react'
import { useParams, Link } from 'react-router-dom';

import { CAuthService } from '../../service/cauth';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import { Pipe } from '../../pipes/pipe';
import { useNavigate } from "react-router-dom";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

export interface GoalsProps {
    params: any
    navigate:any
};
  
export type GoalsState = {
    goalsList: []
};

class Goals extends React.Component<GoalsProps, GoalsState>{

    cAuthService: any = new CAuthService()
    pipe: any = new Pipe()
    constructor(props: any) {
        super(props);
        this.state = {
            goalsList: [],
        };
    }

    rows = [];

    getGoals() {
        this.cAuthService.getGoals(this.props.params.id)
            .then((resp: any) => {
                const aList: any = []
                if (resp.data.data) {
                    resp.data.data.forEach((item: any) => {
                        const a = item
                        aList.push(a)
                    });
                }
                console.log("gsfhkjfh", resp)
                this.setState({ goalsList: aList})
            });
    }
    componentDidMount() {
        this.getGoals()
    }
    render() {
        return (
            <div>
                <div className="col-12 mx-2 " >
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link to="/dashboard/account" >
                            Account
                        </Link>
                        <Link to="" style={{ color: 'gray', fontSize: '20px', textDecoration: "none", fontWeight: 'bold' }}>
                            Goals
                        </Link>
                    </Breadcrumbs>
                </div>
                 <TableContainer component={Paper}>
                    <Table sx={{}} aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell>Name</StyledTableCell>
                                <StyledTableCell>Progress</StyledTableCell>
                                <StyledTableCell>Time Frame</StyledTableCell>
                                <StyledTableCell>Per Week</StyledTableCell>
                                <StyledTableCell>Status</StyledTableCell>
                                <StyledTableCell>Type</StyledTableCell>
                                <StyledTableCell>Date</StyledTableCell>
                                
                                <StyledTableCell align="right">More</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.state.goalsList.map((row: any) => (
                                <StyledTableRow key={row.cycleId}>
                                    {/* <StyledTableCell component="th" scope="row">
                                        {row.id}
                                    </StyledTableCell> */}
                                    <StyledTableCell component="th" scope="row">
                                        {/* <a style={{ color: '#2D9CDB' }} onClick={() => { this.goToEvent(row.cycleId) }}>{row.name}</a> */}
                                        <p style={{ color: '#2D9CDB' }} onClick={() => { this.goToEvent(row.cycleId) }}>{row.icon} {row.name}</p>
                                    </StyledTableCell>
                                    <StyledTableCell component="th" scope="row">
                                    {row.type === 'FINANCIAL' &&  this.financialProgress(row)}
                                    {row.type === 'LEARN' &&  this.learnProgress(row)}
                                    </StyledTableCell>
                                    {/* <StyledTableCell component="th" scope="row">
                                    <img className="tableLogo" src={row.icon} alt='' />
                                    </StyledTableCell> */}
                                    <StyledTableCell component="th" scope="row">
                                        {row.timeFrame}
                                    </StyledTableCell>
                                    <StyledTableCell component="th" scope="row">
                                        {row.availableTimePerWeek}
                                    </StyledTableCell>
                                    <StyledTableCell component="th" scope="row">
                                        {row.status}
                                    </StyledTableCell>
                                    <StyledTableCell component="th" scope="row">
                                        {row.type}
                                    </StyledTableCell>
                                    <StyledTableCell component="th" scope="row">
                                        {this.pipe.dateForm(row.startDateTime)} - {this.pipe.dateForm(row.endDateTime)}
                                    </StyledTableCell>
                                
                                    <StyledTableCell align="right">
                                    </StyledTableCell>
                                </StyledTableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        )
    }

    goToEvent(cycleId:any) {
        console.log('cycleId', cycleId)
        this.props.navigate(`${cycleId}/events`)
    }

    financialProgress(row: any) {
        console.log(';adsadsa', row)
        return (
            <>
                ${row.progress}/${row.realTarget} earned
            </>
        )
    }

    learnProgress(row: any) {
        console.log(';adsadsa', row)
        return (
            <>
                {row.progress}/{row.realTarget/60} hours completed
            </>
        )
    }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function (props: GoalsProps) {
    const params = useParams();
    const navigate = useNavigate();
    return <Goals {...props} params={params} navigate={navigate} />;
  }
