import React from 'react'
import { useParams, Link } from 'react-router-dom';

import { CAuthService } from '../../service/cauth';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import { Pipe } from '../../pipes/pipe';
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import moment from 'moment';


const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

export interface EventsProps {
    params: any
};

export type EventsState = {
    goalsList: []
    goalType: any
};


class Events extends React.Component<EventsProps, EventsState>{

    cAuthService: any = new CAuthService()
    pipe: any = new Pipe()
    constructor(props: any) {
        super(props);
        this.state = {
            goalsList: [],
            goalType: "LEARN"
        };
    }

    getEvents() {
        this.cAuthService.getEvents(this.props.params.accountId, this.props.params.cycleId)
            .then((resp: any) => {
                const aList: any = []
                let goalType;
                if (resp.data.data) {
                    resp.data.data.forEach((item: any) => {
                        const a = item
                        aList.push(a)
                        goalType = a.type
                    });
                }
                this.setState({ goalsList: aList, goalType })
            });
    }
    componentDidMount() {
        console.log("cycleid  ", this.props.params.accountId, this.props.params.cycleId)
        this.getEvents()
    }
    render() {
        return (

            <div>
                <div className="col-12 mx-2 " >
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link to="/dashboard/account" >
                            Account
                        </Link>
                        <Link to={`/dashboard/account/${this.props.params.accountId}/goals`} >
                            Goals
                        </Link>
                        <Link to="" style={{ color: 'gray', fontSize: '20px', textDecoration: "none", fontWeight: 'bold' }}>
                            Events
                        </Link>
                    </Breadcrumbs>
                </div>
                <TableContainer component={Paper}>
                    <Table sx={{}} aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell>Name</StyledTableCell>
                                {/* <StyledTableCell>Event Icon</StyledTableCell> */}
                                <StyledTableCell>Date</StyledTableCell>
                                {this.state.goalType === "FINANCIAL" && <>
                                    <StyledTableCell>Hourly Rate</StyledTableCell></>}
                                <StyledTableCell>Duration</StyledTableCell>
                                <StyledTableCell>Type</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.state.goalsList.map((row: any) => (
                                <StyledTableRow key={row.id}>
                                    <StyledTableCell component="th" scope="row">
                                        <img className="tableLogo" src={row.icon} alt='' /> <a style={{ color: '#2D9CDB' }} href={row.referralLink} target="_blank" rel="noreferrer">{row.title}</a>
                                    </StyledTableCell>
                                    <StyledTableCell component="th" scope="row">
                                        {this.pipe.dateFormat(row.startDateTime)} - {this.pipe.timeFormat(row.endDateTime)}
                                    </StyledTableCell>
                                    <StyledTableCell component="th" scope="row">
                                        {this.state.goalType === 'LEARN' && this.pipe.convertMinToFormatedString(this.timeDuration(row.startDateTime, row.endDateTime))}
                                        {/* {this.pipe.comvertMinToFormatedString(row.startDateTime)} */}
                                    </StyledTableCell>
                                    <StyledTableCell component="th" scope="row">
                                        {row.type}
                                    </StyledTableCell>
                                    {/* {this.state.goalType === "FINANCIAL" && 
                                    <>
                                    <StyledTableCell component="th" scope="row">
                                        {row.hourlyRate}
                                    </StyledTableCell>
                                        <StyledTableCell component="th" scope="row">
                                            {row.company}
                                        </StyledTableCell>
                                    </>} */}
                                </StyledTableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        )
    }

    financialProgress(row: any) {
        console.log(';adsadsa', row)
        return (
            <>
                <a style={{ color: '#2D9CDB' }} href={row.referralLink} target="_blank" rel="noreferrer">{row.title}</a>
            </>
        )
    }

    learnProgress(row: any) {
        console.log(';adsadsa', row)
        return (
            <>
                {row.progress}/{row.realTarget / 60} hours completed
            </>
        )
    }

    timeDuration(startDateTime: any, endDateTime: any) {
        var date1 = moment(startDateTime);
        var date2 = moment(endDateTime);
        var result = date2.diff(date1, 'm');
        console.log("asdsadsadsadsadsa ", result)
        return result;
    }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function (props: EventsProps) {
    const params = useParams();
    return <Events {...props} params={params} />;
}