import React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Dropdown } from 'react-bootstrap';
import { AuthService } from '../../service/auth';
import { Pipe } from '../../pipes/pipe';

// import { useNavigate } from "react-router-dom";
import { Typography } from '@material-ui/core';
import Modal from '@mui/material/Modal';
import TablePagination from '@mui/material/TablePagination';

import { useNavigate } from "react-router-dom";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisH, faTimes } from '@fortawesome/free-solid-svg-icons';
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import { Multiselect } from "multiselect-react-dropdown";
// import { useParams } from 'react-router-dom';

// import { MyTable } from '../tables/table';

import './account.css';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },

}));

export type AccountProps = {
    navigate: any
};
// export interface AccountProps {
//     params: any
// };

export type AccountState = {
    accountsList: []
    modelTemp: boolean
    delTmp: any
    profile: any
    type: string;
    respData: any
    rowsPerPage: number
    totalCount: number
    page: number
    editData: any
    editMod: boolean
};

class Account extends React.Component<AccountProps, AccountState>{
    authService: any = new AuthService()
    pipe: any = new Pipe()
    constructor(props: any) {
        super(props);
        this.state = {
            accountsList: [],
            modelTemp: false,
            delTmp: {},
            profile: "",
            type: "",
            page: 0,
            rowsPerPage: 10,
            respData: "",
            totalCount: 10,
            editData: '',
            editMod: false,
        };
    }
    usersRolesArray = ["ADMIN", "USER"]//[{"key": "ADMIN", value: "Admin"}, {"key": "USER", value: "User"}]
    usersStatusArray = ["ACTIVE", "SUSPEND", "INACTIVE"]
    tableHeader= ["Account ID", "First Name", "Last Name", "Phone Number", "Email", "User Name", "Status", "Role", "Created At", "Last Login Time", "About", "More"]
    rows = [];
    createData(accountId: any, firstName: any, lastName: any, phoneNumber: any, email: any, userName: any, status: any, role: any,
        profile: any, emailVerified: any, createdAt: any, updatedAt: any, lastLoginTime: any, about: any) {
        return {
            accountId, firstName, lastName, phoneNumber, email, userName, status, role,
            profile, emailVerified, createdAt, updatedAt, lastLoginTime, about
        };
    }

    closeModal(user?: any) {
        this.setState({
            delTmp: user,
            modelTemp: !this.state.modelTemp,
        })
    }

    deleteData() {
        this.authService.deleteUser(this.state.delTmp.accountId)
            .then((resp: any) => {
                if (resp.data.message === "DELETE_ACCOUNT_SUCCESS") {
                    this.getAllUsers()
                    this.setState({
                        delTmp: {},
                        modelTemp: !this.state.modelTemp,
                    })
                }
            });
    }

    handleChangeRowsPerPage = (event: any) => {
        this.setState({ rowsPerPage: event.target.value })
    }

    handleChangePage = (event: any, newPage: number) => {
        this.setState({ page: newPage }, () => {
            this.getAllUsers();
        })

    }

    getAllUsers() {
        this.authService.getAccounts("", this.state.page, this.state.rowsPerPage)
            .then((resp: any) => {
                const aList: any = []
                if (resp.data.data) {
                    resp.data.data.data.forEach((item: any) => {
                        const a = this.createData(item.accountId, item.firstName, item.lastName, item.phoneNumber, item.email, item.userName, item.status,
                            item.role, item.profile, item.emailVerified, item.createdAt, item.updatedAt, item.lastLoginTime, item.about)
                        aList.push(a)
                    });
                }
                this.setState({
                    accountsList: aList,
                    totalCount: resp.data.data.totalRecords
                })
            });
    }
    componentDidMount() {
        this.getAllUsers()
    }

    editModal(row: any) {
        this.setState({
            editData: row,
            editMod: !this.state.editMod,
        })
    }

    saveAccount() {
        let updateData = {
            role: this.state.editData.role,
            status: this.state.editData.status
        }
        this.authService.updateAccount(this.state.editData.accountId, updateData)
            .then((resp: any) => {
                this.setState({
                    editData: {},
                    editMod: !this.state.editMod
                })
            });
    }

    handleChange = (event: any, key: any) => {
        var data = this.state.editData
        data[key] = event.target.value
        this.setState({ editData: data })
    }

    render() {
        return (
            <>
                <div role="presentation">
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link href='/'>
                            Account
                        </Link>
                    </Breadcrumbs>
                </div>
                {<div className="mainDiv" >
                    {/* <MyTable tableHeader={this.tableHeader} totalCount={this.state.totalCount}
                    rowsPerPage={this.state.rowsPerPage} page={this.state.page}
                    handleChangePage={this.handleChangePage}
                    handleChangeRowsPerPage={this.handleChangeRowsPerPage}></MyTable> */}
                     
                <TableContainer component={Paper}  >
                        <Table sx={{}} aria-label="customized table">
                            <TableHead>
                                <TableRow>
                                {this.tableHeader.map((row: any) => (
                                    <StyledTableCell>{row}</StyledTableCell>
                                ))}
                                    {/* <StyledTableCell>First Name</StyledTableCell>
                                    <StyledTableCell>Last Name</StyledTableCell>
                                    <StyledTableCell>Phone Number</StyledTableCell>
                                    <StyledTableCell>Email</StyledTableCell>
                                    <StyledTableCell>User Name</StyledTableCell>
                                    <StyledTableCell>Status</StyledTableCell>
                                    <StyledTableCell>Role</StyledTableCell>
                                    <StyledTableCell>Created At</StyledTableCell>
                                    <StyledTableCell>Last Login Time</StyledTableCell>
                                    <StyledTableCell>About</StyledTableCell>
                                    <StyledTableCell>More</StyledTableCell> */}
                                </TableRow>
                            </TableHead>
                            <TableBody >
                                {this.state.accountsList.map((row: any) => (
                                    <StyledTableRow key={row.accountId}>
                                        <StyledTableCell component="th" scope="row">
                                            {row.accountId}
                                        </StyledTableCell>
                                        <StyledTableCell component="th" scope="row">
                                            {row.firstName}
                                        </StyledTableCell>
                                        <StyledTableCell component="th" scope="row">
                                            {row.lastName}
                                        </StyledTableCell>
                                        <StyledTableCell component="th" scope="row">
                                            {row.phoneNumber}
                                        </StyledTableCell>
                                        <StyledTableCell component="th" scope="row">
                                            {row.email}
                                        </StyledTableCell>
                                        <StyledTableCell component="th" scope="row">
                                            {row.userName}
                                        </StyledTableCell>
                                        <StyledTableCell component="th" scope="row">
                                            {row.status}
                                        </StyledTableCell>
                                        <StyledTableCell component="th" scope="row">
                                            {row.role}
                                        </StyledTableCell>
                                        <StyledTableCell component="th" scope="row">
                                            {this.pipe.dateFormat(row.createdAt)}
                                        </StyledTableCell>
                                        <StyledTableCell component="th" scope="row">
                                            {this.pipe.dateFormat(row.lastLoginTime)}
                                        </StyledTableCell>
                                        <StyledTableCell component="th" scope="row">
                                            {row.about}
                                        </StyledTableCell>
                                        <StyledTableCell align="right">
                                            <Dropdown>
                                                <Dropdown.Toggle variant="none" id="dropdown-basic"> <FontAwesomeIcon icon={faEllipsisH} /> </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    <Dropdown.Item onClick={() => { this.navigateToGoal(row.accountId) }}><b>Goals</b></Dropdown.Item>
                                                    <Dropdown.Item onClick={() => { this.editModal(row) }}><b>Edit</b></Dropdown.Item>
                                                    <Dropdown.Item onClick={() => { this.loginActivity(row.accountId) }}><b>Login Activity</b></Dropdown.Item>
                                                    <Dropdown.Item onClick={() => { this.closeModal(row) }}><b>Delete</b></Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </StyledTableCell>
                                    </StyledTableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={this.state.totalCount}
                        rowsPerPage={this.state.rowsPerPage}
                        page={this.state.page}
                        onPageChange={this.handleChangePage}
                        onRowsPerPageChange={this.handleChangeRowsPerPage}
                    />

                    <Modal
                        open={this.state.modelTemp}>
                        <div style={{
                            position: "absolute",
                            width: 550,
                            height: 350,
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            background: 'white',
                            borderRadius: '9px',
                            outline: "none"
                        }} >
                            <div className="col-12">
                                <label className="col-12 pt-3 pe-4 text-end"><FontAwesomeIcon className="col-1" onClick={() => { this.closeModal() }} icon={faTimes} /></label>
                                <Typography variant="h6" id="modal-title" className="pt-4 pb-4 row d-flex justify-content-center text-center sticky-top">
                                    <b>Confirm Delete Account!</b>
                                </Typography>
                                <Typography className="text-center" variant="subtitle1" id="simple-modal-description">
                                    <p className="pb-5">Are you sure you want to delete {this.state.delTmp ? <b>{this.state.delTmp.firstName} {this.state.delTmp.lastName}</b> : ""}?</p>

                                </Typography>
                                <div className=" mt-4 row justify-content-center">
                                    <button onClick={() => { this.closeModal() }} className="button1 col-12  col-md-4 me-3"><b>Cancel</b></button>
                                    <button onClick={() => { this.deleteData() }} className="button2 col-12 col-md-4 ms-3"><b>Yes</b></button>
                                </div>
                            </div>
                        </div>
                    </Modal>

                    <Modal
                        open={this.state.editMod}>
                        <div style={{
                            position: "absolute",
                            width: 550,
                            height: 850,
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            background: 'white',
                            borderRadius: '9px',
                            outline: "none"
                        }} >
                            <div className="col-12">
                                <label className="col-12 pt-3 pe-4 text-end"><FontAwesomeIcon className="col-1" onClick={() => { this.editModal('') }} icon={faTimes} /></label>
                                <Typography variant="h6" id="modal-title" className="pt-4 pb-4 row d-flex justify-content-center text-center sticky-top">
                                    <b>Edit Account Details! {this.state.editData.name}</b>
                                </Typography>
                                <div className="col-12 row p-0 m-0  justify-content-center">
                                    <span className="col-12 row p-0 m-0  justify-content-center">
                                        <label className="text">First Name</label>
                                        <input className="select1 " value={this.state.editData.firstName} type="text" onChange={(event) => { this.handleChange(event, 'firstName') }} disabled></input>
                                        <label className="text mt-2">Last Name</label>
                                        <input className="select1 " type="text" value={this.state.editData.lastName} onChange={(event) => { this.handleChange(event, 'lastName') }} disabled></input>
                                        <label className="text mt-2">Phone Number</label>
                                        <input className="select1 " type="text" value={this.state.editData.phoneNumber} onChange={(event) => { this.handleChange(event, 'phoneNumber') }} disabled></input>
                                        <label className="text mt-2">Email</label>
                                        <input className="select1 " type="text" value={this.state.editData.email} onChange={(event) => { this.handleChange(event, 'email') }} disabled></input>
                                        <label className="text mt-2">User Name</label>
                                        <input className="select1 " type="text" value={this.state.editData.userName} onChange={(event) => { this.handleChange(event, 'userName') }} disabled></input>
                                        <label className="text mt-2">Role</label>
                                        <div className="multiselect col-12 p-0 m-0 row justify-content-center">
                                            <Multiselect showArrow singleSelect={true} options={this.usersRolesArray} isObject={false}
                                                selectedValues={this.state.editData.role} onSelect={(event: any, list: any) => {
                                                    let editData = this.state.editData
                                                    editData.role = list
                                                    this.setState({ editData })
                                                }} />
                                        </div>
                                        <label className="text mt-2">Status</label>
                                        <div className="multiselect col-12 p-0 m-0 row justify-content-center">
                                            <Multiselect showArrow singleSelect={true} options={this.usersStatusArray} isObject={false}
                                                selectedValues={this.state.editData.status} onSelect={(event: any, list: any) => {
                                                    let editData = this.state.editData
                                                    editData.status = list
                                                    this.setState({ editData })
                                                }} />
                                        </div>
                                    </span>
                                </div>

                                <div className=" mt-5 row justify-content-center ">
                                    <button className="savebutton" onClick={() => { this.saveAccount() }}><b>Save</b></button>
                                </div>
                            </div>
                        </div>
                    </Modal>
                </div >}
            </>
        )
    }
    navigateToGoal(accountId: any) {
        this.props.navigate(`${accountId}/goals`)
    }

    loginActivity(accountId: any) {
        this.props.navigate(`${accountId}/loginActivity`)
    }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function (props: AccountProps) {
    const navigate = useNavigate();
    return <Account {...props} navigate={navigate} />;
}

// eslint-disable-next-line import/no-anonymous-default-export
// export default function (props: AccountProps) {
//     const params = useParams();
//     // const navigate = useNavigate();
//     return <Account {...props} params={params}  />;
// }
