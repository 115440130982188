import React from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import { useParams } from 'react-router-dom';
import { AuthService } from '../../service/auth';
import TablePagination from '@mui/material/TablePagination';
import { Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisH, faTimes } from '@fortawesome/free-solid-svg-icons';

import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import { Multiselect } from "multiselect-react-dropdown";
import { ConfirmDelete } from '../../utils/Delete';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

export interface SkillsProps {
    params: any
};

export type SkillsState = {
    accountsList: []
    page: number
    respData: any
    rowsPerPage: number
    totalCount: number
    modelTemp: boolean
    editData: any
    editMod: boolean
    categoryList: []
    formType: string
    update: []
    deleteItem: any
    showDeleteView: boolean

};
export class Skills extends React.Component<SkillsProps, SkillsState>{
    authService: any = new AuthService()
    constructor(props: any) {
        super(props);
        this.state = {
            accountsList: [],
            page: 0,
            rowsPerPage: 10,
            respData: "",
            totalCount: 10,
            modelTemp: false,
            editData: '',
            editMod: false,
            categoryList: [],
            formType: 'CREATE',
            update: [],
            deleteItem: {},
            showDeleteView: false
        };
    }

    rows = [];
    createData(id: any, name: any, categories: any) {
        return {
            id, name, categories
        };
    }
    list = [];
    categoryData(name: any, categoryId:any) {
        return {
            name, categoryId
        };
    }

    openDialogh(row: any, type: string) {
        let cData: any = {}
        console.log('UPDATE Log', row, type)
        cData['id'] = row.id ? row.id : ''
        cData['name'] = row.name ? row.name : ''
        cData['categories'] = row.categories ? row.categories : ''
        cData['categoryId'] =  row.categories? row.categories[0].categoryId : ''       

    this.setState({ editData: cData,editMod: true,formType: type
    })
    }
    closeDialogh() {
        this.setState({
            editMod: false,
            formType: 'CREATE'

        })
    }

    OnSeletDeleteOption(skill?: any) {
        console.log('OnSeletDeleteOption', skill)
        this.setState({
            deleteItem: skill,
            showDeleteView: true
        })
    }

    handleChangeRowsPerPage = (event: any) => {
        this.setState({ rowsPerPage: event.target.value })
    }

    handleChangePage = (event: any, newPage: number) => {
        this.setState({ page: newPage }, () => {
            this.getSkills();
        })
    }
    handleChange = (event: any, key: any) => {
        var data = this.state.editData
        data[key] = event.target.value
        this.setState({ editData: data })
    }

    getSkills() {
        this.authService.getALLSkills(this.state.page, this.state.rowsPerPage)
            .then((resp: any) => {
                const aList: any = []
                if (resp.data.data) {
                    resp.data.data.data.forEach((item: any) => {
                        const a = this.createData(item.id, item.name, item.categories)
                        aList.push(a)
                    });
                }
                console.log("gfdgh", aList)
                this.setState({ accountsList: aList, totalCount: resp.data.data.totalRecords })
            });
    }

    getCategory() {
        this.authService.getALLCategory(0, 1000)
            .then((resp: any) => {
                const aList: any = []
                if (resp.data.data) {
                    resp.data.data.data.forEach((item: any) => {
                        const a = this.categoryData(item.name,item.categoryId )
                        aList.push(a)
                    });
                }
                this.setState({ categoryList: aList, totalCount: resp.data.data.totalRecords })
            });
    }
    componentDidMount() {
        this.getSkills()
        this.getCategory()
    }

    onSave() {
        let skillData = this.state.editData
        let saveObj:any = {}
        saveObj['name'] = skillData.name
        saveObj['categoryId'] = skillData.categoryId

        if (this.state.formType === 'CREATE') {
            this.createSkill(saveObj)
        } else {
            saveObj['id'] = skillData.id
            this.updateSkill(skillData.id, saveObj)
        }
    }

    createSkill(saveObj: any) {
        this.authService.addSkills(saveObj)
            .then((resp: any) => {
                if(resp.status === 200) {
                    this.closeDialogh()
                    this.getSkills()
                }
            })
        console.log('create dataaa', saveObj)
    }

    updateSkill(skillId: any, uData: any) {
        this.authService.updateSkills(skillId, uData)
            .then((resp: any) => {
                if(resp.status === 200) {
                    this.closeDialogh()
                    this.getSkills()
                }
            })
    }

    deleteData() {
        this.authService.deleteSkills(this.state.deleteItem.id)
        .then((resp: any) => {
            console.log('Delete Skill List', resp)
            if(resp.status === 200) {
                this.getSkills()
                this.closeDeleteModel()
            }
        })
    }

    onSelect(selectedItem:any) {
        console.log('onSelect', selectedItem[0])
        let uData = this.state.editData
        uData['categoryId'] =  selectedItem[0].categoryId
        this.setState({editMod: uData })
    }
    
    closeDeleteModel() {
		this.setState({
			showDeleteView: false,
		})
	}

    tableHeader = ["Skills ID", "Skills Name", "Categories"]

    render() {
        return (
            <>
            <span className="col-6">
                    <button className="addbtn text-center mt-2 mb-3" onClick={() => { this.openDialogh('', 'CREATE') }} ><b>Add</b></button>
                </span>
                <TableContainer component={Paper}>
                    <Table sx={{}} aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                {this.tableHeader.map((row: any) => (
                                    <StyledTableCell>{row}</StyledTableCell>
                                ))}
                                <StyledTableCell align="right">More</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.state.accountsList.map((row: any) => (
                                <StyledTableRow key={row.id}>
                                    <StyledTableCell component="th" scope="row">
                                        {row.id}
                                    </StyledTableCell>
                                    <StyledTableCell component="th" scope="row">
                                        {row.name}
                                    </StyledTableCell>
                                    <StyledTableCell component="th" scope="row">
                                            {row.categories[0].name}<br />
                                            {row.categories[0].displayName}
                                    </StyledTableCell>
                                    <StyledTableCell align="right">
                                        <Dropdown>
                                            <Dropdown.Toggle id="dropdown-basic" variant="none">
                                                <FontAwesomeIcon icon={faEllipsisH} />
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                <Dropdown.Item onClick={() => { this.openDialogh(row, 'UPDATE') }}><b>Edit</b></Dropdown.Item>
                                                <Dropdown.Item onClick={() => { this.OnSeletDeleteOption(row) }}><b>Delete</b></Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </StyledTableCell>
                                </StyledTableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={this.state.totalCount}
                    rowsPerPage={this.state.rowsPerPage}
                    page={this.state.page}
                    onPageChange={this.handleChangePage}
                    onRowsPerPageChange={this.handleChangeRowsPerPage}
                />
               {this.state.showDeleteView === true ? ConfirmDelete("Confirm Delete Name!", this.OnSeletDeleteOption.bind(this),
				 this.deleteData.bind(this), this.closeDeleteModel.bind(this)) : null}
                {this.state.editMod && this.editSkills()}
            </>
        )
    }
    editSkills() {
        return (
            <Modal
                open={this.state.editMod}>
                <div style={{
                    position: "absolute",
                    width: 550,
                    height: 750,
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    background: 'white',
                    borderRadius: '9px',
                    outline: "none"
                }} >
                    <div className="col-12">
                        <label className="col-12 pt-3 pe-4 text-end"><FontAwesomeIcon className="col-1" onClick={() => { this.closeDialogh() }} icon={faTimes} /></label>
                        <Typography variant="h6" id="modal-title" className="pt-4 pb-4 row d-flex justify-content-center text-center sticky-top">
                            <b>{this.state.formType === 'CREATE' ? 'CREATE' : 'UPDATE'} SKILLS</b>
                        </Typography>
                        <div className="col-12 row p-0 m-0  justify-content-center">
                            <span className="col-12 row p-0 m-0  justify-content-center">
                                <label className="text">Skills Name</label>
                                <input className="select1 " value={this.state.editData.name} type="text" onChange={(event) => { this.handleChange(event, 'name') }}></input>
                                <label className="text mt-4" >Category</label>
                                <div className="multiselect col-12 p-0 m-0 row justify-content-center">
                                    <Multiselect showArrow singleSelect={true} options={this.state.categoryList} isObject={true}
                                        displayValue="name"
                                        selectedValues={this.state.editData.category}
                                        onSelect={(item)=> {
                                            this.onSelect(item)
                                        }}
                                        />
                                   
                                </div>
                            </span>
                        </div>

                        <div className=" mt-5 row justify-content-center ">
                            <button className="savebutton" onClick={() => { this.onSave() }} disabled={!this.state.editData.name}><b>Save</b></button>
                        </div>
                    </div>
                </div>
            </Modal>
        )
    }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function (props: SkillsProps) {
    const params = useParams();
    return <Skills {...props} params={params} />;
}
