import React from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import { useParams } from 'react-router-dom';
import { AuthService } from '../../service/auth';
import TablePagination from '@mui/material/TablePagination';
import { Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisH, faTimes } from '@fortawesome/free-solid-svg-icons';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import { ConfirmDelete } from '../../utils/Delete';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
	[`&.${tableCellClasses.head}`]: {
		backgroundColor: theme.palette.common.black,
		color: theme.palette.common.white,
	},
	[`&.${tableCellClasses.body}`]: {
		fontSize: 14,
	},
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
	'&:nth-of-type(odd)': {
		backgroundColor: theme.palette.action.hover,
	},
	'&:last-child td, &:last-child th': {
		border: 0,
	},
}));
export interface BlackListProps {
	params: any
};

export type BlackListState = {
	accountsList: []
	page: number
	respData: any
	rowsPerPage: number
	totalCount: number
	modelTemp: boolean
	editData: any
	editMod: boolean
	formType: string
	deleteItem: any
	showDeleteView: boolean
};

export class BlackList extends React.Component<BlackListProps, BlackListState>{
	authService: any = new AuthService()
	constructor(props: any) {
		super(props);
		this.state = {
			accountsList: [],
			page: 0,
			rowsPerPage: 10,
			respData: "",
			totalCount: 10,
			modelTemp: false,
			editData: '',
			editMod: false,
			formType: 'CREATE',
			deleteItem: {},
			showDeleteView: false,
		};
	}
	rows = [];
	createData(id: any, name: any) {
		return {
			id,
			name
		};
	}

	openDialogh(row: any, type: string) {
		let cData: any = {}
		console.log('UPDATE Log', row, type)
		cData['id'] = row.id ? row.id : ''
		cData['name'] = row.name ? row.name : ''
		console.log('cData', cData)
		this.setState({
			editData: cData,
			editMod: true,
			formType: type
		})
	}

	closeDialogh() {
		this.setState({
			editMod: false,
			formType: 'CREATE'
		})
	}

	OnSeletDeleteOption(black?: any) {
		this.setState({
			deleteItem: black,
			showDeleteView: true
		})
	}

	handleChangeRowsPerPage = (event: any) => {
		this.setState({ rowsPerPage: event.target.value })
	}

	handleChangePage = (event: any, newPage: number) => {
		this.setState({ page: newPage }, () => {
			this.getBlackList();
		})
	}

	getBlackList() {
		this.authService.getALLBlackList(this.state.page, this.state.rowsPerPage)
			.then((resp: any) => {
				const aList: any = []
				if (resp.data.data) {
					resp.data.data.forEach((item: any) => {
						const a = this.createData(item.id, item.name)
						aList.push(a)
					});
				}
				console.log("List", aList)
				this.setState({ accountsList: aList })
			});
	}
	componentDidMount() {
		this.getBlackList()
	}


	handleChange = (event: any, key: any) => {
		var data = this.state.editData
		data[key] = event.target.value
		this.setState({ editData: data })
	}

	onSave() {
		let blackListData = this.state.editData
		console.log("BlackList Details Update", blackListData)
		let saveObj:any = {}
		saveObj['name'] = blackListData.name
		if (this.state.formType === 'CREATE') {
			this.createBlackList(saveObj)
		} else {
			this.updateBlackList(blackListData.id, saveObj)
		}
	}

	createBlackList = (saveObj: any) => {
		this.authService.addBlackList(saveObj)
			.then((resp: any) => {
				if (resp.status === 200) {
					this.closeDialogh()
					this.getBlackList()
				}
			})
		console.log('BlackList dataaa', saveObj)
	}

	updateBlackList(blackListId: any, uData: any) {
		this.authService.updateBlackList(blackListId, uData)
			.then((resp: any) => {
				console.log('update BlackList', resp)
				this.closeDialogh()
				this.getBlackList()
			})
	}

	deleteData = () => {
		this.authService.deleteBlackList(this.state.deleteItem.id)
			.then((resp: any) => {
				console.log('Delete Black List', resp)
				if (resp.status === 200) {
					this.getBlackList()
					this.closeDeleteModel()
				}
			});
	}

	closeDeleteModel() {
		this.setState({
			showDeleteView: false,
		})
	}

	render() {
		return (
			<>
				<span className="col-6">
					<button className="addbtn text-center mt-2 mb-3" onClick={() => { this.openDialogh('', 'CREATE') }} ><b>Add</b></button>
				</span>
				<TableContainer component={Paper}>
					<Table sx={{}} aria-label="customized table">
						<TableHead>
							<TableRow>
								<StyledTableCell>Name</StyledTableCell>
								<StyledTableCell align="right">More</StyledTableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{this.state.accountsList.map((row: any) => (
								<StyledTableRow key={row.id}>
									<StyledTableCell component="th" scope="row">
										{row.name}
									</StyledTableCell>
									<StyledTableCell align="right">
										<Dropdown>
											<Dropdown.Toggle variant="none" id="dropdown-basic"> <FontAwesomeIcon icon={faEllipsisH} /> </Dropdown.Toggle>
											<Dropdown.Menu>
												<Dropdown.Item onClick={() => { this.openDialogh(row, 'UPDATE') }}><b>Edit</b></Dropdown.Item>
												<Dropdown.Item onClick={() => { this.OnSeletDeleteOption(row) }}><b>Delete</b></Dropdown.Item>
											</Dropdown.Menu>
										</Dropdown>
									</StyledTableCell>
								</StyledTableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
				<TablePagination
					rowsPerPageOptions={[5, 10, 25]}
					component="div"
					count={this.state.totalCount}
					rowsPerPage={this.state.rowsPerPage}
					page={this.state.page}
					onPageChange={this.handleChangePage}
					onRowsPerPageChange={this.handleChangeRowsPerPage}
				/>
				{this.state.showDeleteView === true ? ConfirmDelete("Confirm Delete Name!", this.OnSeletDeleteOption.bind(this), this.deleteData.bind(this), this.closeDeleteModel.bind(this)) : null}
				{this.state.editMod && this.editBlacklist()}
			</>
		)
	}
	editBlacklist() {
		return (
			<Modal
				open={this.state.editMod}>
				<div style={{
					position: "absolute",
					width: 550,
					height: 450,
					top: '50%',
					left: '50%',
					transform: 'translate(-50%, -50%)',
					background: 'white',
					borderRadius: '9px',
					outline: "none"
				}} >
					<div className="col-12">
						<label className="col-12 pt-3 pe-4 text-end"><FontAwesomeIcon className="col-1" onClick={() => { this.closeDialogh() }} icon={faTimes} /></label>
						<Typography variant="h6" id="modal-title" className="pt-4 pb-5 row d-flex justify-content-center text-center sticky-top">
							<b>{this.state.formType === 'CREATE' ? 'CREATE' : 'UPDATE'} BLACK LIST</b>
						</Typography>
						<div className="col-12 row p-0 m-0  justify-content-center">
							<span className="col-12 row p-0 m-0  justify-content-center">
								<label className="text mt-3">Category Name</label>
								<input className="select1 mb-5" value={this.state.editData.name} type="text" onChange={(event) => { this.handleChange(event, 'name') }}></input>
							</span>
						</div>
						<div className=" mt-3 row justify-content-center ">
							<button className="savebutton" onClick={() => { this.onSave() }} disabled={!this.state.editData.name}><b>Save</b></button>
						</div>
					</div>
				</div>
			</Modal>
		)
	}
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function (props: BlackListProps) {
	const params = useParams();
	return <BlackList {...props} params={params} />;
}
