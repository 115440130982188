import React from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import { useParams, Link } from 'react-router-dom';
import { Pipe } from '../../pipes/pipe';
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import { AuthService } from '../../service/auth';
import TablePagination from '@mui/material/TablePagination';


const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

export interface LoginActivityProps {
  params: any
};

export type LoginActivityState = {
  accountsList: []
  respData: any
  rowsPerPage: number
  totalCount: number
  page: number
};

export class LoginActivity extends React.Component<LoginActivityProps, LoginActivityState>{
  authService: any = new AuthService()
  pipe: any = new Pipe()
  constructor(props: any) {
    super(props);
    this.state = {
      accountsList: [],
      respData: "",
      totalCount: 10,
      rowsPerPage: 10,
      page: 0,
    };
  }

  rows = [];
  createData(accountId: any, id: any, userAgent: any, createdDate: any, updatedDate: any, ipAddress: any) {
    return {
      accountId, id, userAgent, createdDate, updatedDate, ipAddress
    };
  }

  handleChangeRowsPerPage = (event: any) => {
    this.setState({ rowsPerPage: event.target.value })
  }

  handleChangePage = (event: any, newPage: number) => {
    this.setState({ page: newPage }, () => {
      this.getLoginActivity();
    })
  }

  getLoginActivity() {
    this.authService.getUserLoginActivity(this.props.params.id, this.state.page, this.state.rowsPerPage)
      .then((resp: any) => {
        // this.setState({ respData: resp.data })
        const aList: any = []
        if (resp.data.data) {
          resp.data.data.data.forEach((item: any) => {
            const a = this.createData(item.accountId, item.id, item.userAgent, item.createdDate, item.updatedDate, item.ipAddress)
            aList.push(a)
          });
        }
        this.setState({
          accountsList: aList, totalCount: resp.data.data.totalRecords
        })
      });
  }

  componentDidMount() {
    this.getLoginActivity()
  }
  render() {
    return (
      <div>
        <div className="col-12 mx-2 " >
          <Breadcrumbs aria-label="breadcrumb">
            <Link to="/dashboard" >
              Account
            </Link>
            <Link to="" style={{ color: 'gray', fontSize: '20px', textDecoration: "none", fontWeight: 'bold' }}>
              Login Activity
            </Link>
          </Breadcrumbs>
        </div>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>User Agent</StyledTableCell>
                <StyledTableCell>IP Addrress</StyledTableCell>
                <StyledTableCell>Time</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.state.accountsList.map((row: any) => (
                <StyledTableRow key={row.id}>
                  <StyledTableCell component="th" scope="row">
                    {row.userAgent}
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row">
                    {row.ipAddress}
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row">
                    {this.pipe.dateFormat(row.createdDate)}
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={this.state.totalCount}
          rowsPerPage={this.state.rowsPerPage}
          page={this.state.page}
          onPageChange={this.handleChangePage}
          onRowsPerPageChange={this.handleChangeRowsPerPage}
        />
      </div>
    )
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function (props: LoginActivityProps) {
  const params = useParams();
  return <LoginActivity {...props} params={params} />;
}