
import React, { Component } from 'react';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import OtpInput from 'react-otp-input';
import { ToastContainer, toast } from 'react-toastify';
import { AuthService } from '../../service/auth'
import './login.css';
import 'react-toastify/dist/ReactToastify.css';
import Gig from '../../assets/gig.svg';
import { useNavigate } from "react-router-dom";

export type LoginProps = {
	navigate: any
	setToken: any
};

export type LoginState = {
	countryCode: string;
	phoneNumber: string; // like this
	otp: string,
	viewType: String,
	otpHasErrored: boolean
};

class Logpage extends Component<LoginProps, LoginState>{
	authService: any = new AuthService()
	constructor(props: LoginProps) {
		super(props);
		this.state = {
			countryCode: "1",
			phoneNumber: "",
			otp: "",
			viewType: 'PHONENUMBER',
			otpHasErrored: false,
		};
	}
	render() {
		return (
			<div>
				<div className="col-12">
					<div className="col-10 mt-5 mb-5 ms-5" >
						<img src={Gig} alt='' />
						<div className="12 mt-4" style={{ borderBottom: " 1px inset" }}></div>
					</div>
					{this.state.viewType === 'PHONENUMBER' ? (this.PhoneNumber()) : this.Otp()}
				</div>
				<ToastContainer
					position="top-right"
					autoClose={5000}
					hideProgressBar={false}
					newestOnTop={false}
					closeOnClick
					rtl={false}
					pauseOnFocusLoss
					draggable
					pauseOnHover />
			</div>
		)
	}
	changePhoneNumber() {
	}

	async sendOtp() {
		let phoneNumber = this.state.phoneNumber.replace(this.state.countryCode, "")
		if (phoneNumber.length) {
			await this.authService.requestOTP({ phoneNumber: phoneNumber, countryCode: `+${this.state.countryCode}` })
				.then((resp: any) => {
					if (resp.status === 200) {
						this.setState({ viewType: 'OTP' })
					}
				})
				.catch((error: any) => {
					if (error && error.response && error.response.status === 400) {
						toast.error(`${error.response.data.message}`, {
							position: "top-right",
							autoClose: 5000,
							hideProgressBar: false,
							closeOnClick: true,
							pauseOnHover: true,
							draggable: true,
							progress: undefined,
						});
					}
				})
		}
	}


	async validateOtp() {
		let phoneNumber = this.state.phoneNumber.replace(this.state.countryCode, "")

		if (phoneNumber.length) {
			// this.setState({ viewType: 'OTP' })
			await this.authService.verifyOTP({
				phoneNumber: phoneNumber,
				countryCode: `+${this.state.countryCode}`,
				otp: this.state.otp,
				channelId: "WEB"
			})
				.then((resp: any) => {
					if (resp.status === 200) {
						localStorage.setItem('refreshToken', resp.data.data.refreshToken);
						localStorage.setItem('authorization', resp.data.data.authorization);
						this.props.navigate('/dashboard')
						// this.setState({ viewType: 'OTP' })
					}
				});
		}
	}

	onPhoneChanged = (phone: any, country: any, e: any, formattedValue: any) => {
		this.setState({
			countryCode: country.dialCode,
			phoneNumber: `${phone}`
		})
	}

	PhoneNumber = () => {
		return (
			<div className="col-12">
				<div className="col-12 mt-5 mb-5">
					<h1 style={{ textAlign: "center" }}>Welcome Back</h1>
					<p className="font mb-5">Log in to admin portal</p>
				</div>
				<div className="col-12 d-flex align-center justify-content-center position-relative mt-5">
					<div className="col-9 col-md-9 col-lg-7 col-xl-6 d-flex ">
						<div className="justify-content-center row col-12 mt-1">
							<div className="col-9 pe-0">
								<div className="">
									<PhoneInput
										country={'us'}
										value={this.state.phoneNumber}
										placeholder="+91 9900152313"
										containerStyle={{ width: '100%' }}
										onChange={this.onPhoneChanged} />
								</div>
							</div>
							<div onClick={() => { this.sendOtp() }} className="col-2 float-start Button_button__sL3tD Button_signupButton__pGlka  ">
								<label className="text-center pt-4 fa-lg"  >
									<FontAwesomeIcon icon={faArrowRight} />
								</label>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}

	Otp() {
		return (
			<>
				<div className="col-12 mt-5 mb-5" style={{ textAlign: "center" }}>
					<h1 className="mt-4">Let's get you started!</h1>
					<p className="mt-4">Please enter the verification code sent to your number</p>
				</div>
				<div className="d-flex justify-content-center position-relative mt-5">
					<OtpInput
						value={this.state.otp}
						onChange={(value: string) => {
							this.setState({ otp: value }, () => {
								if (value.length === 6) {
									this.validateOtp()
								}
							})
						}}
						hasErrored={this.state.otpHasErrored}
						numInputs={6}
						separator={<span style={{ width: "10px" }}></span>}
						isInputNum={true}
						shouldAutoFocus={true}
						inputStyle={{
							border: "1px solid gray",
							borderRadius: "10px",
							width: "54px",
							height: "60px",
							fontSize: "25px",
							color: "#000",
							fontWeight: "500",
							caretColor: "black"
						}}
						focusStyle={{
							border: "1px solid #CFD3DB",
							outline: "none"
						}}
						errorStyle={{
							border: "1px solid red",
							borderRadius: "10px",
							width: "54px",
							height: "60px",
							fontSize: "25px",
							color: "#000",
							fontWeight: "500",
							caretColor: "black"
						}}
					/>
				</div>
				<div className="col-12 mt-4 mb-5">
					<p className="w-100 text-center">
						Wrong phone number? <b className="px-1">Update</b>
					</p>
				</div>
				<div className="d-flex justify-content-center position-relative">
					<button className=" button col-12 col-md-3 mt-5" >
						<b>Get started</b>
					</button>
				</div>
			</>
		)
	}

	verfiyOtp() {
		this.context.router.transitionTo('/dashboard');
	}
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function (props: LoginProps) {
	const navigate = useNavigate();
	return <Logpage {...props} navigate={navigate} />;
}
