// import api from './config/axios';
import isDev from '../utils/isDev';
import axiosInstance from './config/axios';
import { apiConfig } from './config/config';
  

//class to represent our Auth service
export class AuthService {
  config: any
  mainUrl: String
  adminUrl: String = "http:198.168.136"

  authorizationToken: any

  constructor() {
    if (isDev()) {
      this.mainUrl = apiConfig.mainUriDev;
    } else {
      this.mainUrl = apiConfig.mainUri;
    }

    this.authorizationToken = `${apiConfig.bearer}${localStorage.getItem('authorization')}`;

    this.config = {
      requestOTP: '/ups/account/requestOTP/admin',
      verifyOTP: '/ups/account/verifyOTP',
      getAccessToken: '/ups/account/refreshToken',
      accessTokenServer: '/api/gigturboAccessToken',
      refreshTokenServer: '/api/gigturboRefreshToken',
      accounts: '/admin/user',
      logout: '/api/logout',
      deleteUser: '/admin/user/',
      getCompany: '/admin/company',
      getCourse: '/admin/course',
      getInterest: '/admin/interest',
      getCategory: '/admin/category',
      getQuestion: '/admin/question',
      getSkills: '/admin/master/skill',
      getBlackList: '/admin/blacklist',
      getTestAccount: '/admin/test/account',
      addTestAccount: '/admin/test/account',
      deletePhoneNumber: '/admin/test/account/',
      getLearnPlatForm: '/admin/learn/platform',
      getCareer: '/admin/career',
      getAllPotentialUsers: '/admin/potentialuser',
      getLoginActivity: '/admin/user',
      suspendAccount: '/admin/user/suspend',
      updateQuestion: '/admin/question',
      updateCareer: '/admin/career',
      updateCategory: '/admin/category',
      addCategoryData: '/admin/category',
      addCareerData: '/admin/career',
      addInterestData: '/admin/interest',
      updateInterestData: '/admin/interest',
      UPDATE_COMPANY: '/admin/company/',
      ADD_COMPANY: '/admin/company/',
      UPDATE_COURSE: '/admin/course/',
      ADD_COURSE: '/admin/course',
      UPDATE_SKILLS: '/admin/master/skill',
      ADD_SKILLS: '/admin/master/skill',
      UPDATE_BLACKLIST: '/admin/blacklist',
      ADD_BLACKLIST: '/admin/blacklist',
      DELETE_BLACKLIST: '/admin/blacklist/',
      DELETE_CATEGORY: '/admin/category/',
      DELETE_CAREER: '/admin/career/',
      DELETE_SKILL: '/admin/master/skill/',
      DELETE_COMPANY: '/admin/company/',
      DELETE_COURSE: '/admin/course/',
      DELETE_INTEREST: '/admin/interest/',
      DASH_BOARD_GOAL:'/admin/dashboard/goal',
      DASH_BOARD_CATEGORY: '/admin/dashboard/category',
      DASH_BOARD_COMPANY: '/admin/dashboard/company',
      DASH_BOARD_Popular_Course: '/admin/dashboard/course',
    }
  }
  
  getUrl(key: string) {
    return `${this.mainUrl}${this.config[key]}`
  }

  /**
 * @returns gets the refresh token from browser cookies
 */


  deleteUser(accountId: String) {
    return axiosInstance({
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json', 'Authorization': this.authorizationToken },
      url: `${this.getUrl('deleteUser')}${accountId}`
    })
  }

  /**
 * @param data object with countryCode and phoneNumber
 * @returns 
 */
  requestOTP(data: any) {
    return axiosInstance({
      method: 'POST',
      url: `${this.mainUrl}${this.config.requestOTP}`,
      data
    });
  }

  /**
   * @param data object with countryCode, phoneNumber and OTP
   * @returns 
   */
  verifyOTP(data: any) {
    return axiosInstance({
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      url: `${this.mainUrl}${this.config.verifyOTP}`,
      data
    });
  }


  getAccounts(name:any, pageNumber: Number, itemsPePage: Number) { 
    return axiosInstance({
      method: 'GET',
      headers: { 'Content-Type': 'application/json', Authorization: this.authorizationToken },
      url: `${this.mainUrl}${this.config.accounts}?itemsPerPage=${itemsPePage}&pageNumber=${pageNumber}`,
    });
  }

  getUserLoginActivity(accountId: any, pageNumber: Number, itemsPePage: Number) {
    return axiosInstance({
      method: 'GET',
      headers: { 'Content-Type': 'application/json', Authorization: this.authorizationToken },
      url: `${this.getUrl('getLoginActivity')}/${accountId}?pageNumber=${pageNumber}&itemsPerPage=${itemsPePage}`,
    });
  }

  getALLCompanies(pageNumber: Number, itemsPePage: Number) {
    return axiosInstance({
      method: 'GET',
      headers: { 'Content-Type': 'application/json', Authorization: this.authorizationToken },
      url: `${this.getUrl('getCompany')}?pageNumber=${pageNumber}&itemsPerPage=${itemsPePage}`
    });
  }

  getALLCourse(pageNumber: Number, itemsPePage: Number) {
    return axiosInstance({
      method: 'GET',
      headers: { 'Content-Type': 'application/json', Authorization: this.authorizationToken },
      url: `${this.getUrl('getCourse')}?pageNumber=${pageNumber}&itemsPerPage=${itemsPePage}`
    });
  }

  getALLInterest(pageNumber: Number, itemsPePage: Number) {
    return axiosInstance({
      method: 'GET',
      headers: { 'Content-Type': 'application/json', Authorization: this.authorizationToken },
      url: `${this.getUrl('getInterest')}?pageNumber=${pageNumber}&itemsPerPage=${itemsPePage}`
    });
  }
  getALLCategory(pageNumber: Number, itemsPePage: Number) {
    return axiosInstance({
      method: 'GET',
      headers: { 'Content-Type': 'application/json', Authorization: this.authorizationToken },
      url: `${this.getUrl('getCategory')}?pageNumber=${pageNumber}&itemsPerPage=${itemsPePage}`
    });
  }

  getALLQuestion(pageNumber: Number, itemsPePage: Number){
    return axiosInstance({
      method: 'GET',
      headers: { 'Content-Type': 'application/json', Authorization: this.authorizationToken },
      url: `${this.getUrl('getQuestion')}?pageNumber=${pageNumber}&itemsPerPage=${itemsPePage}`
    });
  }

  getALLSkills(pageNumber: Number, itemsPePage: Number){
    return axiosInstance({
      method: 'GET',
      headers: { 'Content-Type': 'application/json', Authorization: this.authorizationToken },
      url: `${this.getUrl('getSkills')}?pageNumber=${pageNumber}&itemsPerPage=${itemsPePage}`
    });
  }

  getALLBlackList(){
    return axiosInstance({
      method: 'GET',
      headers: { 'Content-Type': 'application/json', Authorization: this.authorizationToken },
      url: `${this.getUrl('getBlackList')}`
    });
  }

  getALLTestAccount(pageNumber: Number, itemsPePage: Number){
    return axiosInstance({
      method: 'GET',
      headers: { 'Content-Type': 'application/json', Authorization: this.authorizationToken },
      url: `${this.getUrl('getTestAccount')}?pageNumber=${pageNumber}&itemsPerPage=${itemsPePage}`
    });
  }
  addAccount(data: any) {
    return axiosInstance({
      method: 'POST',
      headers: { 'Content-Type': 'application/json', Authorization: this.authorizationToken },
      url: `${this.getUrl('addTestAccount')}`,
      data
    });
  }

  deletePhoneNumber(phoneNumber: String) {
    return axiosInstance({
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json', 'Authorization': this.authorizationToken },
      url: `${this.getUrl('deletePhoneNumber')}${phoneNumber}`
    })
  }

  getALLLearnPlatForm(pageNumber: Number, itemsPePage: Number){
    return axiosInstance({
      method: 'GET',
      headers: { 'Content-Type': 'application/json', Authorization: this.authorizationToken },
      url: `${this.getUrl('getLearnPlatForm')}?pageNumber=${pageNumber}&itemsPerPage=${itemsPePage}`
    });
  }

  getALLCareer(pageNumber: Number, itemsPePage: Number){
    return axiosInstance({
      method: 'GET',
      headers: { 'Content-Type': 'application/json', Authorization: this.authorizationToken },
      url: `${this.getUrl('getCareer')}?pageNumber=${pageNumber}&itemsPerPage=${itemsPePage}`
    });
  }

  getALLPotentialUsers(pageNumber: Number, itemsPePage: Number){
    return axiosInstance({
      method: 'GET',
      headers: { 'Content-Type': 'application/json', Authorization: this.authorizationToken },
      url: `${this.getUrl('getAllPotentialUsers')}?pageNumber=${pageNumber}&itemsPerPage=${itemsPePage}`
    });
  }

  updateAccount(accountId: any, data: any) {
    return axiosInstance({
      method: 'POST',
      headers: { 'Content-Type': 'application/json', Authorization: this.authorizationToken },
      url: `${this.getUrl('deleteUser')}${accountId}/update`,
      data
    });
  }
  
  updateQuestion(questionId: any,data:any ) {
    return axiosInstance({
      method: 'PUT',
      headers: { 'Content-Type': 'application/json', Authorization: this.authorizationToken },
      url: `${this.getUrl('updateQuestion')}/${questionId}`,
      data
    });
  }

  updateCareer(careerId: any,data:any ) {
    return axiosInstance({
      method: 'PUT',
      headers: { 'Content-Type': 'application/json', Authorization: this.authorizationToken },
      url: `${this.getUrl('updateCareer')}/${careerId}`,
      data
    });
  }
  updateCategory(categoryId: any,data:any ) {
    return axiosInstance({
      method: 'PUT',
      headers: { 'Content-Type': 'application/json', Authorization: this.authorizationToken },
      url: `${this.getUrl('updateCategory')}/${categoryId}`,
      data
    });
  }

  addCareer(data: any) {
    return axiosInstance({
      method: 'POST',
      headers: { 'Content-Type': 'application/json', Authorization: this.authorizationToken },
      url: `${this.getUrl('addCareerData')}`,
      data
    });
  }

  addCategory(data: any) {
    return axiosInstance({
      method: 'POST',
      headers: { 'Content-Type': 'application/json', Authorization: this.authorizationToken },
      url: `${this.getUrl('addCategoryData')}`,
      data
    });
  }

  updateInterest(interestId: any,data:any ) {
    return axiosInstance({
      method: 'PUT',
      headers: { 'Content-Type': 'application/json', Authorization: this.authorizationToken },
      url: `${this.getUrl('updateInterestData')}/${interestId}`,
      data
    });
  }

  addInterest(data: any) {
    return axiosInstance({
      method: 'POST',
      headers: { 'Content-Type': 'application/json', Authorization: this.authorizationToken },
      url: `${this.getUrl('addInterestData')}`,
      data
    });
  }

  updateCompany(companyId: any,data:any ) {
    return axiosInstance({
      method: 'PUT',
      headers: { 'Content-Type': 'application/json', Authorization: this.authorizationToken },
      url: `${this.getUrl('UPDATE_COMPANY')}/${companyId}`,
      data
    });
  }

  addCompany(data: any) {
    return axiosInstance({
      method: 'POST',
      headers: { 'Content-Type': 'application/json', Authorization: this.authorizationToken },
      url: `${this.getUrl('ADD_COMPANY')}`,
      data
    });
  }
  updateCourse(courseId: any,data:any ) {
    return axiosInstance({
      method: 'PUT',
      headers: { 'Content-Type': 'application/json', Authorization: this.authorizationToken },
      url: `${this.getUrl('UPDATE_COURSE')}/${courseId}`,
      data
    });
  }

  addCourse(data: any) {
    return axiosInstance({
      method: 'POST',
      headers: { 'Content-Type': 'application/json', Authorization: this.authorizationToken },
      url: `${this.getUrl('ADD_COURSE')}`,
      data
    });
  }

  updateSkills(skillId: any,data:any ) {
    return axiosInstance({
      method: 'PUT',
      headers: { 'Content-Type': 'application/json', Authorization: this.authorizationToken },
      url: `${this.getUrl('UPDATE_SKILLS')}/${skillId}`,
      data
    });
  }

  addSkills(data: any) {
    return axiosInstance({
      method: 'POST',
      headers: { 'Content-Type': 'application/json', Authorization: this.authorizationToken },
      url: `${this.getUrl('ADD_SKILLS')}`,
      data
    });
  }

  updateBlackList(id: any,data:any ) {
    return axiosInstance({
      method: 'PUT',
      headers: { 'Content-Type': 'application/json', Authorization: this.authorizationToken },
      url: `${this.getUrl('UPDATE_BLACKLIST')}/${id}`,
      data
    });
  }

  addBlackList(data: any) {
    return axiosInstance({
      method: 'POST',
      headers: { 'Content-Type': 'application/json', Authorization: this.authorizationToken },
      url: `${this.getUrl('ADD_BLACKLIST')}`,
      data: [data]
    });
  }

  deleteBlackList(id: any) {
    return axiosInstance({
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json', 'Authorization': this.authorizationToken },
      url: `${this.getUrl('DELETE_BLACKLIST')}${id}`
    })
  }

  deleteCategory(categoryId: any) {
    return axiosInstance({
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json', 'Authorization': this.authorizationToken },
      url: `${this.getUrl('DELETE_CATEGORY')}${categoryId}`
    })
  }

  deleteCareerData(careerId: any) {
    return axiosInstance({
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json', 'Authorization': this.authorizationToken },
      url: `${this.getUrl('DELETE_CAREER')}${careerId}`
    })
  }
  deleteSkills(skillId: any) {
    return axiosInstance({
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json', 'Authorization': this.authorizationToken },
      url: `${this.getUrl('DELETE_SKILL')}${skillId}`
    })
  }

  deleteCompany(companyId: any) {
    return axiosInstance({
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json', 'Authorization': this.authorizationToken },
      url: `${this.getUrl('DELETE_COMPANY')}${companyId}`
    })
  }

  deleteCourse(courseId: any) {
    return axiosInstance({
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json', 'Authorization': this.authorizationToken },
      url: `${this.getUrl('DELETE_COURSE')}${courseId}`
    })
  }

  deleteInterest(interestId: any) {
    return axiosInstance({
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json', 'Authorization': this.authorizationToken },
      url: `${this.getUrl('DELETE_INTEREST')}${interestId}`
    })
  }


  getDashBoardGoal(data:any ) {
    return axiosInstance({
      method: 'POST',
      headers: { 'Content-Type': 'application/json', Authorization: this.authorizationToken },
      url: `${this.getUrl('DASH_BOARD_GOAL')}`,
      data
    });
  }

  getDashBoardTopCategory(){
    return axiosInstance({
      method: 'GET',
      headers: { 'Content-Type': 'application/json', Authorization: this.authorizationToken },
      url: `${this.getUrl('DASH_BOARD_CATEGORY')}`
    });
  }

  getDashBoardTopCompany(){
    return axiosInstance({
      method: 'GET',
      headers: { 'Content-Type': 'application/json', Authorization: this.authorizationToken },
      url: `${this.getUrl('DASH_BOARD_COMPANY')}`
    });
  }

  getDashBoardPopularCourse(){
    return axiosInstance({
      method: 'GET',
      headers: { 'Content-Type': 'application/json', Authorization: this.authorizationToken },
      url: `${this.getUrl('DASH_BOARD_Popular_Course')}`
    });
  }
}
