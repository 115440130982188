import axios from "axios";
import { apiConfig } from './config';

const instance = axios
    .create({
        baseURL: apiConfig.mainUri
    })

instance.interceptors.request.use(
    config => {
        const authorization = localStorage.getItem("authorization");

        if (authorization) {
            config.headers['Authorization'] = "Bearer " + authorization;
        }
        config.headers['Content-Type'] = 'application/json';
        return config;
    },
    error => {
        Promise.reject(error)
    });

instance.interceptors.response.use((response) => {
    return response
}, function (error) {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {

        originalRequest._retry = true;
        const refreshToken = localStorage.getItem("refreshToken");
        return instance.post('/ups/account/refreshToken',
            {
                "refreshToken": refreshToken
            })
            .then(res => {
                if (res.status === 200) {
                    localStorage.setItem("authorization", res.data.data.authorization)
                    return instance(originalRequest);
                }
            })
    }
    return Promise.reject(error);
});

export default instance