import isDev from '../utils/isDev';
import axiosInstance from './config/axios';
import { apiConfig } from './config/config';
  

//class to represent our Auth service
export class CAuthService {
  config: any
  mainUrl: String
  adminUrl: String = "http:198.168.136"

  authorizationToken: any

  constructor() {
    if (isDev()) {
      this.mainUrl = apiConfig.mainUriDev;
    } else {
      this.mainUrl = apiConfig.mainUri;
    }

    this.authorizationToken = `${apiConfig.bearer}${localStorage.getItem('authorization')}`;

    this.config = {
      getGoals: '/gtcalendar/user/goals',
      getEvents: '/gtcalendar/gtevents'
    }
  }

   
  getUrl(key: string) {
    return `${this.mainUrl}${this.config[key]}`
  }

  getGoals(qAccountId: any){
    return axiosInstance({
      method: 'GET',
      headers: { 'Content-Type': 'application/json', Authorization: this.authorizationToken },
      url: `${this.getUrl('getGoals')}?qAccountId=${qAccountId}`
    });
  }

  getEvents(qAccountId: any, cycleId: any){
      console.log("ggggggggggggggggggggggggggggggggggggg  ", cycleId)
    return axiosInstance({
      method: 'POST',
      headers: { 'Content-Type': 'application/json', Authorization: this.authorizationToken },
      url: `${this.getUrl('getEvents')}?qAccountId=${qAccountId}`,
      data: {cycleId: cycleId}
    });
  }
}