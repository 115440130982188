import React from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import { useParams } from 'react-router-dom';
import { AuthService } from '../../service/auth';
import TablePagination from '@mui/material/TablePagination';
import { Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisH, faTimes } from '@fortawesome/free-solid-svg-icons';

import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import { ConfirmDelete } from '../../utils/Delete';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

export interface PotentialUserProps {
    params: any
};

export type PotentialUserState = {
    potentialUsersList: []
    page: number
    respData: any
    rowsPerPage: number
    totalCount: number
    showDeleteView: boolean
    editData: any
    editMod: boolean
    addMod: boolean
    formType: string
    deleteItem: any
};

export class PotentialUser extends React.Component<PotentialUserProps, PotentialUserState>{
    authService: any = new AuthService()

    constructor(props: any) {
        super(props);
        this.state = {
            potentialUsersList: [],
            page: 0,
            rowsPerPage: 10,
            respData: "",
            totalCount: 10,
            showDeleteView: false,
            editData: '',
            editMod: false,
            addMod: false,
            formType: 'CREATE',
            deleteItem: {}

        };
    }

    rows = [];
    createData(createdDate: any, userId: any, firstName: any, lastName: any, university: any, 
        primaryCollege: any, major: any, schoolGrade:any, graduationDate: any,
        jobApplied: any, email: any, phoneNumber: any) {
        return {
            createdDate, userId, firstName, lastName, university, 
            primaryCollege, major, schoolGrade, graduationDate,
            jobApplied, email, phoneNumber
        };
    }
    
    openDialogh(row: any, type: string) {
        let cData: any = {}
            console.log('UPDATE Log', row, type)
            cData['id'] = row.id ? row.id : ''
            cData['name'] = row.name ? row.name : ''
            cData['earnings'] = row.earnings ? row.earnings : null
        console.log('cData', cData)

        this.setState({
            editData: cData,
            editMod: true,
            formType: type
        })
    }

    closeDialogh() {
        this.setState({
            editMod: false,
            formType: 'CREATE'

        })
    }

    OnSeletDeleteOption(career?: any) {
        this.setState({
            deleteItem: career,
            showDeleteView: true
        })
    }

    handleChangeRowsPerPage = (event: any) => {
        this.setState({ rowsPerPage: event.target.value })
    }

    handleChangePage = (event: any, newPage: number) => {
        this.setState({ page: newPage }, () => {
            this.getPotentialUsers();
        })
    }

    getPotentialUsers() {
        this.authService.getALLPotentialUsers(this.state.page, this.state.rowsPerPage)
            .then((resp: any) => {
                const aList: any = []
                if (resp.data.data) {
                    console.log(resp.data.data);
                    resp.data.data.data.forEach((item: any) => {
                        const a = this.createData(item.createdDate, 
                            item.potentialUserId, 
                            item.firstName,
                            item.lastName,
                            item.university,
                            item.primaryCollege,
                            item.major,
                            item.schoolGrade,
                            item.graduationDate,
                            item.jobApplied,
                            item.email,
                            item.phoneNumber)
                        aList.push(a)
                    });
                }
                this.setState({ potentialUsersList: aList, totalCount: resp.data.data.totalRecords })
            });
    }

    componentDidMount() {
        this.getPotentialUsers()
    }
    
    handleChange = (event: any, key: any) => {
        var data = this.state.editData
        data[key] = event.target.value
        this.setState({ editData: data })
        
    }

    onSave() {
        let careerData = this.state.editData
        console.log("Career Details Update", careerData)
        let saveObj = { name: '', earnings: '' }
        saveObj.name = careerData.name
        saveObj.earnings = careerData.earnings
        if (this.state.formType === 'CREATE') {
            this.createCareer(saveObj)
        } else {
            this.updateCareer(careerData.id, saveObj)
        }
    }

    createCareer = (saveObj: any) => {
        this.authService.addCareer(saveObj)
            .then((resp: any) => {
                if (resp.status === 200) {
                    this.closeDialogh()
                    this.getPotentialUsers()
                }
            })
        console.log('create dataaa', saveObj)
    }

    updateCareer(careerId: any, uData: any) {
        this.authService.updateCareer(careerId, uData)
            .then((resp: any) => {
                console.log('update Career', resp)
                this.closeDialogh()
                this.getPotentialUsers()
            })
    }
    
    deleteData = () => {
        console.log('this.state.deleteItem ',this.state.deleteItem )
        this.authService.deleteCareerData(this.state.deleteItem.id)
        .then((resp: any) => {
            console.log('Delete Black List', resp)
            if(resp.status === 200) {
                this.getPotentialUsers()
                this.closeDeleteModel()
            }
        });
    }

    closeDeleteModel() {
		this.setState({
			showDeleteView: false,
		})
	}
    tableHeader = ["Created Date", "ID", "First Name", "Last Name", "Email", "Job Applied", "University", 
        "Primary College", "Major", "School Grade", "Graduation Date", "Phone Number"]
    render() {
        return (
            <>
                <span className="col-6">
                    <button className="addbtn text-center mt-2 mb-3" onClick={() => { this.openDialogh('', 'CREATE') }} ><b>Add</b></button>
                </span>
                <TableContainer component={Paper}>
                    <Table sx={{}} aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                {this.tableHeader.map((row: any) => (
                                     <StyledTableCell>{row}</StyledTableCell>
                                ))}
                                <StyledTableCell align="right">More</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.state.potentialUsersList.map((row: any) => (
                                <StyledTableRow key={row.id}>
                                    <StyledTableCell component="th" scope="row">
                                        {row.createdDate}
                                    </StyledTableCell>
                                    <StyledTableCell component="th" scope="row">
                                        {row.userId}
                                    </StyledTableCell>
                                    <StyledTableCell component="th" scope="row">
                                        {row.firstName}
                                    </StyledTableCell>
                                    <StyledTableCell component="th" scope="row">
                                        {row.lastName}
                                    </StyledTableCell>
                                    <StyledTableCell component="th" scope="row">
                                        {row.email}
                                    </StyledTableCell>
                                    <StyledTableCell component="th" scope="row">
                                        {row.jobApplied}
                                    </StyledTableCell>
                                    <StyledTableCell component="th" scope="row">
                                        {row.university}
                                    </StyledTableCell>
                                    <StyledTableCell component="th" scope="row">
                                        {row.primaryCollege}
                                    </StyledTableCell>
                                    <StyledTableCell component="th" scope="row">
                                        {row.major}
                                    </StyledTableCell>
                                    <StyledTableCell component="th" scope="row">
                                        {row.schoolGrade}
                                    </StyledTableCell>
                                    <StyledTableCell component="th" scope="row">
                                        {row.graduationDate}
                                    </StyledTableCell>
                                    <StyledTableCell component="th" scope="row">
                                        {row.phoneNumber}
                                    </StyledTableCell>
                                    <StyledTableCell align="right">
                                        <Dropdown>
                                            <Dropdown.Toggle variant="none" id="dropdown-basic"> <FontAwesomeIcon icon={faEllipsisH} /> </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                <Dropdown.Item onClick={() => { this.openDialogh(row, 'UPDATE') }}><b>Edit</b></Dropdown.Item>
                                                <Dropdown.Item onClick={() => { this.OnSeletDeleteOption(row) }}><b>Delete</b></Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </StyledTableCell>
                                </StyledTableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={this.state.totalCount}
                    rowsPerPage={this.state.rowsPerPage}
                    page={this.state.page}
                    onPageChange={this.handleChangePage}
                    onRowsPerPageChange={this.handleChangeRowsPerPage}
                />
                {this.state.showDeleteView  ? ConfirmDelete("Confirm Delete Name!", this.OnSeletDeleteOption.bind(this),
				 this.deleteData.bind(this), this.closeDeleteModel.bind(this)) : null}
                {this.state.editMod ? this.editeCareer() : null}
            </>
        )
    }
    editeCareer() {
        return (
            <>
                <Modal
                    open={this.state.editMod}>
                    <div style={{
                        position: "absolute",
                        width: 550,
                        height: 550,
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        background: 'white',
                        borderRadius: '9px',
                        outline: "none"
                    }} >
                        <div className="col-12">
                            <label className="col-12 pt-3 pe-4 text-end"><FontAwesomeIcon className="col-1" onClick={() => { this.closeDialogh() }} icon={faTimes} /></label>
                            <Typography variant="h6" id="modal-title" className="pt-4 pb-4 row d-flex justify-content-center text-center sticky-top">
                                <b>{this.state.formType === 'CREATE' ? 'CREATE' : 'UPDATE'} CAREER</b>
                            </Typography>
                            <div className="col-12 row p-0 m-0  justify-content-center">
                                <span className="col-12 row p-0 m-0  justify-content-center">
                                    <label className="text">Name</label>
                                    <input className="select1 " value={this.state.editData.name} type="text" onChange={(event) => { this.handleChange(event, 'name') }}></input>
                                    <label className="text mt-4">Earnings</label>
                                    <input className="select1 " type="number" value={this.state.editData.earnings} onChange={(event) => { this.handleChange(event, 'earnings') }}></input>
                                </span>
                            </div>

                            <div className=" mt-5 row justify-content-center ">
                                <button className="savebutton" onClick={() => { this.onSave()}} disabled={!this.state.editData.name || !this.state.editData.earnings }><b>Save</b></button>
                            </div>
                        </div>
                    </div>
                </Modal>
            </>
        )
    }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function (props: PotentialUserProps) {
    const params = useParams();
    return <PotentialUser {...props} params={params} />;
}
