import React, { useState } from 'react';
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate
} from "react-router-dom";
import './App.css';

import Logpage from './mainPage/login/login';
import Dashboard from './mainPage/dashboard/dashboard';
// import Account from './mainPage/account/account';


function App() {
  const [setToken] = useState();
  return (
    <div className="" style={{ width: "100vw", height: "100vh" }}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={
            <Navigate replace to="/dashboard" />
          } />
          <Route path="/login" element={<Logpage navigate setToken={setToken} />} />
          <Route path="/dashboard/*" element={
            <RequireAuth>
              <Dashboard navigate />
            </RequireAuth>
          } /> 
        </Routes>
      </BrowserRouter>
    </div>
  );
}

// @ts-ignore
function RequireAuth({ children }) {
  // const { authed } = useAuth();
  const isAuthenticated = localStorage.getItem("refreshToken");

  return isAuthenticated != null
    ? children
    : <Navigate to="/login" replace />;
}

export default App;
