
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

export const ConfirmDelete = (name: any, close: any, deleteData: any, onClose:any) => {
    return (
        <Modal
            open={true}>
            <div style={{
                position: "absolute",
                width: 550,
                height: 350,
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                background: 'white',
                borderRadius: '9px',
                outline: "none"
            }} >
                <div className="col-12">
                    <label className="col-12 pt-3 pe-4 text-end"><FontAwesomeIcon className="col-1" onClick={() => { onClose() }} icon={faTimes} /></label>
                    <Typography variant="h6" id="modal-title" className="pt-4 pb-4 row d-flex justify-content-center text-center sticky-top">
                        <b>{ name }</b>
                        {/* <b>Confirm Delete Question And Options!</b> */}
                    </Typography>
                    <Typography className="text-center" variant="subtitle1" id="simple-modal-description">
                        <p className="pb-5">Are you sure you want to delete </p>

                    </Typography>
                    <div className=" mt-4 row justify-content-center">
                        <button onClick={() => { onClose() }} className="button1 col-12  col-md-4 me-3"><b>Cancel</b></button>
                        <button className="button2 col-12 col-md-4 ms-3" onClick={() => { deleteData() }}><b>Yes</b></button>
                    </div>
                </div>
            </div>
        </Modal >
    )
}
