import React from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import { useParams } from 'react-router-dom';
import { AuthService } from '../../service/auth';
import TablePagination from '@mui/material/TablePagination';
import { Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisH, faTimes } from '@fortawesome/free-solid-svg-icons';

import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import { Multiselect } from "multiselect-react-dropdown";

import './interest.css';
import { ConfirmDelete } from '../../utils/Delete';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

export interface InterestProps {
    params: any
};

export type InterestState = {
    interestList: []
    page: number
    respData: any
    rowsPerPage: number
    totalCount: number
    modelTemp: boolean
    selectedValues: any
    objectArray: any
    editMod: boolean
    editData: any
    categoryList: []
    formType: string
    deleteCategoryIds: number
    addCategoryIds: []
    deleteItem: any
    showDeleteView: boolean
};

export class Interest extends React.Component<InterestProps, InterestState>  {
    authService: any = new AuthService()
    constructor(props: any) {
        super(props);
        this.state = {
            interestList: [],
            page: 0,
            rowsPerPage: 10,
            respData: "",
            totalCount: 10,
            modelTemp: false,
            editMod: false,
            objectArray: [],
            selectedValues: [],
            editData: '',
            categoryList: [],
            formType: 'CREATE',
            addCategoryIds: [],
            deleteCategoryIds: 50,
            deleteItem: {},
            showDeleteView: false,
        };
    }

    rows = [];
    createData(id: any, name: any, displayName: any, categories: any) {
        return {
            id, name, displayName, categories
        };
    }
    list = [];
    categoryData(displayName: any, categoryId: any) {
        return {
            displayName, categoryId
        };
    }

    openDialogh(row: any, type: string){
        let cData: any = {}
        console.log('UPDATE Log', row, type)
        cData['id'] = row.id ? row.id : ''
        cData['name'] = row.name ? row.name : ''
        cData['displayName'] = row.displayName ? row.displayName : ''
        cData['categories'] = row.categories ? row.categories : ''
        cData['categoryId'] = row.categories ? row.categories[0].categoryId : ''
        console.log('cData', cData)

    this.setState({
        editData: cData,
        editMod: true,
        formType: type,
    })
    }

    closeDialogh() {
        this.setState({
            editMod: false,
            formType: 'CREATE'

        })
    }
    OnSeletDeleteOption(company?: any) {
        this.setState({
            deleteItem: company,
            showDeleteView: true
        })
    }

    handleChangeRowsPerPage = (event: any) => {
        this.setState({ rowsPerPage: event.target.value })
    }

    handleChangePage = (event: any, newPage: number) => {
        this.setState({ page: newPage }, () => {
            this.getInterest();
        })
    }

    getInterest() {
        this.authService.getALLInterest(this.state.page, this.state.rowsPerPage)
            .then((resp: any) => {
                const aList: any = []
                if (resp.data.data) {
                    resp.data.data.data.forEach((item: any) => {
                        const a = this.createData(item.id, item.name, item.displayName, item.categories)
                        aList.push(a)
                    });
                }
                console.log('gfdghf', aList)
                this.setState({ interestList: aList })
            });
    }

    getCategory() {
        this.authService.getALLCategory(0, 1000)
            .then((resp: any) => {
                const aList: any = []
                if (resp.data.data) {
                    resp.data.data.data.forEach((item: any) => {
                        const a = this.categoryData(item.displayName, item.categoryId)
                        aList.push(a)
                    });
                }
                this.setState({ categoryList: aList, totalCount: resp.data.data.totalRecords })
            });
    }


    componentDidMount() {
        this.getInterest()
        this.getCategory()
    }

    handleChange = (event: any, key: any) => {
        var data = this.state.editData
        data[key] = event.target.value
        this.setState({ editData: data })
    }

    onSave() {
        let interestData = this.state.editData
        console.log("Interest Details Update", interestData)
        let saveObj: any = {}
        saveObj['name'] = interestData.name
        saveObj['displayName'] = interestData.displayName
        saveObj['categoryId'] = interestData.categoryId
        if (this.state.formType === 'CREATE') {
            this.createInterest(saveObj)
            this.closeDialogh()
            this.getInterest()
        } else {
            saveObj['id'] = interestData.id
            this.updateInterest(interestData.id, saveObj)
        }
    }

    createInterest = (saveObj: any) => {
        this.authService.addInterest(saveObj)
            .then((resp: any) => {
                if (resp.data.message === "ADD_INTEREST_SUCCESS") {
                    this.closeDialogh()
                }
            })
        console.log('create dataaa', saveObj)
    }

    updateInterest(interestId: any, uData: any) {
        this.authService.updateInterest(interestId, uData)
            .then((resp: any) => {
                console.log('update Interest', resp)
                this.closeDialogh()
                this.getInterest()
            })
    }

    removeDropDown(event:any) {
    //    console.log("removeDropDown", event[0].categoryId) 
    }

    selectDropDown(event: any) {
        console.log("Select Options",event)
    }

    deleteData = () => {
        this.authService.deleteInterest(this.state.deleteItem.id)
        .then((resp: any) => {
            console.log('Delete Interest List', resp)
            if(resp.status === 200) {
                this.getInterest()
                this.closeDeleteModel()
            }
        })
    }

    closeDeleteModel() {
		this.setState({
			showDeleteView: false,
		})
	}

    tableHeader = ["Interest ID", "Name", "Display Name", "Categories"]
    render() {
        return (
            <>
            <span className="col-6">
                    <button className="addbtn text-center mt-2 mb-3" onClick={() => { this.openDialogh('', 'CREATE') }} ><b>Add</b></button>
                </span>
                <TableContainer component={Paper}>
                    <Table sx={{}} aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                {this.tableHeader.map((row: any) => (
                                    <StyledTableCell>{row}</StyledTableCell>
                                ))}
                                <StyledTableCell align="right">More</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.state.interestList.map((row: any) => (
                                <StyledTableRow key={row.id}>
                                    <StyledTableCell component="th" scope="row">
                                        {row.id}
                                    </StyledTableCell>
                                    <StyledTableCell component="th" scope="row">
                                        {row.name}
                                    </StyledTableCell>
                                    <StyledTableCell component="th" scope="row">
                                        {row.displayName? row.displayName : '-'}
                                    </StyledTableCell>
                                    <StyledTableCell component="th" scope="row">
                                        {row.categories.length ? 
                                        
                                        <div>
                                            {row.categories[0].name}<br />
                                        {row.categories[0].displayName}
                                        </div>
                                        : "-" }   
                                    </StyledTableCell>
                                    <StyledTableCell align="right">
                                        <Dropdown>
                                            <Dropdown.Toggle id="dropdown-basic" variant="none">
                                                <FontAwesomeIcon icon={faEllipsisH} />
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                <Dropdown.Item onClick={() => { this.openDialogh(row, 'UPDATE') }}><b>Edit</b></Dropdown.Item>
                                                <Dropdown.Item onClick={() => { this.OnSeletDeleteOption() }} ><b>Delete</b></Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </StyledTableCell>
                                </StyledTableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={this.state.totalCount}
                    rowsPerPage={this.state.rowsPerPage}
                    page={this.state.page}
                    onPageChange={this.handleChangePage}
                    onRowsPerPageChange={this.handleChangeRowsPerPage}
                />
                 {this.state.showDeleteView === true ? ConfirmDelete("Confirm Delete Name!", this.OnSeletDeleteOption.bind(this),
				 this.deleteData.bind(this), this.closeDeleteModel.bind(this)) : null}
                {this.state.editMod && this.editInterest()}
            </>
        )
    }
    editInterest() {
        return(
            <Modal
            open={this.state.editMod}>
            <div style={{
                position: "absolute",
                width: 550,
                height: 750,
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                background: 'white',
                borderRadius: '9px',
                outline: "none"
            }} >
                <div className="col-12">
                    <label className="col-12 pt-3 pe-4 text-end"><FontAwesomeIcon className="col-1" onClick={() => { 
                        this.closeDialogh() 
                         }} icon={faTimes} /></label>
                    <Typography variant="h6" id="modal-title" className="pt-4 pb-4 row d-flex justify-content-center text-center sticky-top">
                        <b>{this.state.formType === 'CREATE' ? 'CREATE' : 'UPDATE'} INTEREST</b>
                    </Typography>
                    <div className="col-12 row p-0 m-0  justify-content-center">
                        <label className="text">Name</label>
                        <input className="select1 " type="text" value={this.state.editData.name} onChange={(event) => { this.handleChange(event, 'name') }}></input>
                        <label className="text mt-4">Display Name</label>
                        <input className="select1 " type="text" value={this.state.editData.displayName} onChange={(event) => { this.handleChange(event, 'displayName') }}></input>
                        <label className="text mt-4">Categories</label>
                    </div>
                    <div className="multiselect col-12 p-0 m-0 row justify-content-center">
                        <Multiselect showArrow options={this.state.categoryList} isObject={true}
                            onRemove={(rEvent:any)=>{
                                    this.removeDropDown(rEvent)
                            }} 
                            onSelect={(sEvent: any) => {
                                this.selectDropDown(sEvent)
                            }}

                            displayValue="displayName" selectedValues={this.state.editData.categories} />
                    </div>
                    <div className=" mt-5 row justify-content-center ">
                        <button className="savebutton" onClick={() => { this.onSave() }} disabled={!this.state.editData.name || !this.state.editData.displayName}><b>Save</b></button>
                    </div>
                </div>
            </div>
        </Modal>

        )
    }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function (props: InterestProps) {
    const params = useParams();
    return <Interest {...props} params={params} />;
}
