import React from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import { useParams } from 'react-router-dom';
import TablePagination from '@mui/material/TablePagination';
import { AuthService } from '../../service/auth';
import { Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisH, faTimes } from '@fortawesome/free-solid-svg-icons';

import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import { ConfirmDelete } from '../../utils/Delete';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

export interface CategoryProps {
    params: any
};

export type CategoryState = {
    categoryList: []
    page: number
    respData: any
    rowsPerPage: number
    totalCount: number
    modelTemp: boolean
    editData: any
    editMod: boolean
    formType: string
    deleteItem: any
    showDeleteView: boolean
};

export class Category extends React.Component<CategoryProps, CategoryState>{
    authService: any = new AuthService()

    constructor(props: any) {
        super(props);
        this.state = {
            categoryList: [],
            page: 0,
            rowsPerPage: 10,
            respData: "",
            totalCount: 10,
            modelTemp: false,
            editData: '',
            editMod: false,
            formType: 'CREATE',
            deleteItem: {},
            showDeleteView: false,
        };
    }

    rows = [];
    createData(categoryId: any, name: any, displayName: any) {
        return {
            categoryId, name, displayName
        };
    }
    openDialogh(row: any, type: string) {
        let cData: any = {}
        console.log('UPDATE Log', row, type)
        cData['categoryId'] = row.categoryId ? row.categoryId : ''
        cData['name'] = row.name ? row.name : ''
        cData['displayName'] = row.displayName ? row.displayName : ''
        console.log('cData', cData)

        this.setState({
            editData: cData,
            editMod: true,
            formType: type
        })
    }

    closeDialogh() {
        this.setState({
            editMod: false,
            formType: 'CREATE'
        })
    }

    OnSeletDeleteOption(black?: any) {
        this.setState({
            deleteItem: black,
            showDeleteView: true
            // modelTemp: !this.state.modelTemp,
        })
    }

    handleChangeRowsPerPage = (event: any) => {
        this.setState({ rowsPerPage: event.target.value })
    }

    handleChangePage = (event: any, newPage: number) => {
        this.setState({ page: newPage }, () => {
            this.getCategory();
        })
    }

    handleChange = (event: any, key: any) => {
        var data = this.state.editData
        data[key] = event.target.value
        this.setState({ editData: data })
    }

    getCategory() {
        this.authService.getALLCategory(this.state.page, this.state.rowsPerPage)
            .then((resp: any) => {
                const aList: any = []
                if (resp.data.data) {
                    resp.data.data.data.forEach((item: any) => {
                        const a = this.createData(item.categoryId, item.name, item.displayName)
                        aList.push(a)
                    });
                }
                console.log('fgasd', aList)
                this.setState({ categoryList: aList, totalCount: resp.data.data.totalRecords })
            });
    }

    onSave(){
        let categoryData = this.state.editData
        console.log("Category Details Update", categoryData)
        let saveObj = {name: '', displayName: ''}
        saveObj.name = categoryData.name 
        saveObj.displayName = categoryData.displayName
        if (this.state.formType === 'CREATE') {
            this.createCategory(saveObj)
        } else {
            this.updateCategory(categoryData.id, saveObj)
        }
    }

    createCategory = (saveObj: any) => {
        this.authService.addCategory(saveObj)
        .then((resp: any) => {
            if (resp.status === 200){
                this.closeDialogh()
                this.getCategory()
                // this.closeDialogh()
            }
        })
        console.log('create dataa', saveObj)
    }

    updateCategory(categoryId: any, uData: any){
        this.authService.updateCategory(categoryId, uData)
        .then((resp: any) => {
            console.log('update Category', resp)
            this.closeDialogh()
            this.getCategory()
        })
    }
    componentDidMount() {
        this.getCategory()
    }

    deleteData = () => {
        this.authService.deleteCategory(this.state.deleteItem.categoryId)
        .then((resp: any) => {
            console.log('Delete Black List', resp)
            if(resp.status === 200) {
                this.getCategory()
                this.closeDeleteModel()
            }
        })
    }
    closeDeleteModel() {
		this.setState({
			showDeleteView: false,
		})
	}

    tableHeader = ["Category ID", "Category Name", "Display Name"]
    render() {
        return (
            <>
            <span className="col-6">
                    <button className="addbtn text-center mt-2 mb-3" onClick={() => { this.openDialogh('', 'CREATE') }} ><b>Add</b></button>
                </span>
                <TableContainer component={Paper}>
                    <Table sx={{}} aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                {this.tableHeader.map((row: any) => (
                                     <StyledTableCell>{row}</StyledTableCell>
                                ))}
                                <StyledTableCell align="right">More</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.state.categoryList.map((row: any) => (
                                <StyledTableRow key={row.categoryId}>
                                    <StyledTableCell component="th" scope="row">
                                        {row.categoryId}
                                    </StyledTableCell>
                                    <StyledTableCell component="th" scope="row">
                                        {row.name}
                                    </StyledTableCell>
                                    <StyledTableCell component="th" scope="row">
                                        {row.displayName}
                                    </StyledTableCell>
                                    <StyledTableCell align="right">
                                        <Dropdown>
                                            <Dropdown.Toggle id="dropdown-basic" variant="none">
                                                <FontAwesomeIcon icon={faEllipsisH} />
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                <Dropdown.Item onClick={() => { this.openDialogh(row, 'UPDATE') }}><b>Edit</b></Dropdown.Item>
                                                <Dropdown.Item onClick={() => { this.OnSeletDeleteOption(row) }}><b>Delete</b></Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </StyledTableCell>
                                </StyledTableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={this.state.totalCount}
                    rowsPerPage={this.state.rowsPerPage}
                    page={this.state.page}
                    onPageChange={this.handleChangePage}
                    onRowsPerPageChange={this.handleChangeRowsPerPage}
                />
               {this.state.showDeleteView === true ? ConfirmDelete("Confirm Delete Name!", this.OnSeletDeleteOption.bind(this),
				 this.deleteData.bind(this), this.closeDeleteModel.bind(this)) : null}
                {this.state.editMod && this.editCategory()}
            </>
        )
    }
    editCategory() {
        return(
            <Modal
            open={this.state.editMod}>
            <div style={{
                position: "absolute",
                width: 550,
                height: 550,
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                background: 'white',
                borderRadius: '9px',
                outline: "none"
            }} >
                <div className="col-12">
                    <label className="col-12 pt-3 pe-4 text-end"><FontAwesomeIcon className="col-1"   onClick={() => {this.closeDialogh()}}icon={faTimes} /></label>
                    <Typography variant="h6" id="modal-title" className="pt-4 pb-4 row d-flex justify-content-center text-center sticky-top">
                        <b>{this.state.formType === 'CREATE' ? 'CREATE' : 'UPDATE'} CATEGORY</b>
                    </Typography>
                    <div className="col-12 row p-0 m-0  justify-content-center">
                        <span className="col-12 row p-0 m-0  justify-content-center">
                            <label className="text">Category Name</label>
                            <input className="select1 " value={this.state.editData.name} type="text" onChange={(event) => { this.handleChange(event, 'name') }}></input>
                            <label className="text mt-4">Display Name</label>
                            <input className="select1 " type="text" value={this.state.editData.displayName} onChange={(event) => { this.handleChange(event, 'displayName') }}></input>
                        </span>
                    </div>

                    <div className=" mt-5 row justify-content-center ">
                        <button className="savebutton" onClick={() => { this.onSave() }} disabled={!this.state.editData.name || !this.state.editData.displayName}><b>Save</b></button>
                    </div>
                </div>
            </div>
        </Modal>
        )
    }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function (props: CategoryProps) {
    const params = useParams();
    return <Category {...props} params={params} />;
}

